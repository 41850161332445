import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import storage from "redux-persist/lib/storage";                // local Storage에 저장하고 싶은 경우
import storageSession from "redux-persist/lib/storage/session"; // session Storage에 저장하고 싶은 경우
import loginReducers from "./reducers/userReducers.jsx";
import langCodeReducers from "./reducers/langCodeReducers.jsx";

// 로그인
const loginPersistConfig = {
    key: 'login',
    storage: storageSession,
    whitelist: ['userInfo', 'rememberMe'],
};

// 언어
const langCodePersistConfig = {
    key: 'langCode',
    storage,
    whitelist: ['langCodeInfo'],
};

const rootReducer = combineReducers({
    login: persistReducer(loginPersistConfig, loginReducers),
    langCode: persistReducer(langCodePersistConfig, langCodeReducers),
});

// configureStore에 전달할 미들웨어 콜백 함수 정의
const middleware = (getDefaultMiddleware) => {
    return [
        // 직렬화 체크 미들웨어 추가
        ...getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
    ];
};

const store = configureStore({
    reducer: rootReducer,
    middleware,
});

export const persistor = persistStore(store);

export default store;