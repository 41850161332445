import i18next from "i18next";
import httpApi from 'i18next-http-backend';
import { initReactI18next } from "react-i18next";
import { API_BASE_URL } from "./config/app-axios";

// 초기화 함수를 외부에서 호출할 수 있도록 변경
export const initializeI18n = async () => {
    // localStorage에서 langCodeInfo 가져오기
    const savedLangCode   = JSON.parse(localStorage.getItem('langCodeInfo') || '{}');
    const defaultLanguage = 'vi';

    // 언어 설정이 없을 경우 기본 언어로 설정
    const langToUse       = savedLangCode && Object.keys(savedLangCode).length > 0 ? savedLangCode : defaultLanguage;

    await i18next
        .use(initReactI18next)
        .use(httpApi)
        .init({
            lng: langToUse,
            fallbackLng: 'vi',
            ns: ['translation'],
            defaultNS: 'translation',
            load: 'languageOnly',
            interpolation: {
                escapeValue: true,
            },
            backend: {
                loadPath: `${API_BASE_URL}/i18n/{{lng}}`,
                allowMultiLoading: true,
                withCredentials: true,
            },
            react: {
                useSuspense: false,
            }
        });
};

// 초기화
initializeI18n();

export default i18next;