import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import CustomToast from '../../components/toast/toast.jsx';
import API from '../../api/api.jsx';

// 지점등록 모달
const Contract001_02 = ({ showModal, setShowBranchEditModal, handleSearch, DetailData, modify, setModify }) => {
    const { t }                                   = useTranslation();

     // 토스트
	const [toast, setToast]               = useState(false);
	const [text, setText]                 = useState('');

    // 수정여부 체크
    const isEditMode        = !!modify;

    const initBranch_code   = isEditMode ? DetailData.branch_code : '';         // 지점코드
    const initBranch_name   = isEditMode ? DetailData.branch_name : '';         // 지점명
    // const initBranch_type   = isEditMode ? DetailData.branch_type : 'HEAD';     // 사무실 종류
    const initBranch_type   = isEditMode ? (DetailData.branch_type === '본점' ? 'HEAD' : (DetailData.branch_type === '지점' ? 'BRANCH' : 'HEAD')) : 'HEAD'; // 사무실 종류
    const initContract_date = isEditMode ? DetailData.contract_date : '';       // 계약일
    const initManager_id    = isEditMode ? DetailData.manager_id : '';          // 지점 매니저 ID
    // const initBranch_status = isEditMode ? DetailData.branch_status : 'NORMAL'; // 지점 상태
    const initBranch_status = isEditMode ? (DetailData.branch_status === '정상' ? 'NORMAL' : (DetailData.branch_status === '해지' ? 'EXPIRED' : 'NORMAL')) : 'NORMAL'; // 상태
    const initBranch_remark = isEditMode ? DetailData.branch_remark : '';       // 적요

    const [formData, setFormData] = useState({
        title            : '',
        biz_code         : '',
        biz_no           : '',
        biz_name         : '',
        biz_reg_date     : '',
        biz_reg_location : '',
        biz_repr_id      : '',
        biz_status       : '',
        biz_remark       : '',
        branch_code      : '',
        branch_name      : '',
        branch_type      : '',
        contract_date    : '',
        manager_id       : '',
        branch_status    : '',
        branch_remark    : '',
    });

    // 렌더링
    useEffect(() => {
        // 모달이 활성화 된 경우
        if (showModal) {
            setFormData({
                title            : 'branch',
                biz_code         : DetailData.biz_code,
                biz_no           : DetailData.biz_no,
                biz_name         : DetailData.biz_name,
                biz_reg_date     : DetailData.biz_reg_date,
                biz_reg_location : DetailData.biz_reg_location,
                biz_repr_id      : DetailData.biz_repr_id,
                biz_status       : DetailData.biz_status,
                biz_remark       : DetailData.biz_remark,
                branch_code      : initBranch_code,
                branch_name      : initBranch_name,
                branch_type      : initBranch_type,
                contract_date    : initContract_date ? new Date(initContract_date) : new Date(),
                manager_id       : initManager_id,
                branch_status    : initBranch_status,
                branch_remark    : initBranch_remark,
            });
        }
    }, [showModal, isEditMode, DetailData, initBranch_code, initBranch_name, initBranch_type, initContract_date, initManager_id, initBranch_status, initBranch_remark]);

    const handleChange = (e, id) => {
        // DatePicker로 날짜 변경시
        if (id === 'contract_date') {
            setFormData((prevData) => ({ ...prevData, [id]: e }));
        } else {
            // 일반적인 input 필드 값 변경시
            const { value } = e.target;
            setFormData((prevData) => ({ ...prevData, [id]: value }));
        }
    };

    // 이메일 정규표현식
    const isEmailValid = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    // 저장 함수
    const handleSave = async (e) => {
        e.preventDefault();

        // 필수 항목들의 키 배열
        const requiredFields = [
            'branch_name',
            'contract_date',
            'manager_id',
        ];

        // 필수 항목 중 하나라도 값이 비어있으면 알림 띄우기
        for (const field of requiredFields) {
            // 수정인 경우에는 지점코드, 지점매니저ID는 수정이 불가하므로
            // 필수 값에서 제외
            if (isEditMode && ['manager_id'].includes(field)) {
                continue;
            }

            if (!formData[field]) {
                alert('필수 값이 입력되지 않았습니다.');
                return;
            }
        };

        // 등록시 지점매니저ID는 email 형태
        if (!isEditMode && !isEmailValid(formData.manager_id)) {
            alert('지점매니저ID는 email 형식입니다.');
            return;
        };

        try {
            const confirmationMessage = isEditMode ? '지점을 수정하시겠습니까?' : '지점을 등록하시겠습니까?';
            const confirmed           = window.confirm(confirmationMessage);

            if (confirmed) {
                const savedData = await API.saveContractInfo({ formData });
    
                // 생성
                if (savedData.code === 201) {
                    const message = savedData.result.content.message;
    
                    setText(message);
    
                    setToast(true);
    
                    handleClose();
    
                    return;
                };
    
                // 수정
                if (savedData.code === 200) {
                    const message = savedData.result.content.message;
    
                    setText(message);
    
                    setToast(true);
    
                    handleClose();
    
                    return;
                }
            }

        } catch (error) {
            const message = error.response.data.result.content;
            alert(message);
        };
    };

    // 닫기 함수
    const handleClose = () => {
        setFormData({
            title            : '',
            biz_code         : '',
            biz_no           : '',
            biz_name         : '',
            biz_reg_date     : '',
            biz_reg_location : '',
            biz_repr_id      : '',
            biz_status       : '',
            biz_remark       : '',
            branch_code      : '',
            branch_name      : '',
            branch_type      : '',
            contract_date    : '',
            manager_id       : '',
            branch_status    : '',
            branch_remark    : '',
        });

        setShowBranchEditModal(false);

        setModify(false);

        handleSearch();
    };

    return (
        <>
            <CustomToast show={toast} setToast={setToast} text={text} />
            <Modal show={showModal} onHide={handleClose} centered dialogClassName="modal-lg" backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{t('STAFF_MANAGEMENT.사업자정보', { defaultValue: '사업자정보' })}</Modal.Title>
                </Modal.Header>

                <Modal.Body className="text-start">
                    <div className='row ms-0'>
                        <div className='col-md-2 bg-light d-flex align-items-center pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.사업자 번호`, { defaultValue: '사업자 번호' })}</div>
                        <div className='col-md-4 pt-2 pb-2'>
                            <input className="form-control border-0" type="text" value={DetailData.biz_no || ''} readOnly={true} />
                        </div>

                        <div className='col-md-2 bg-light d-flex align-items-center pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.사업자명`, { defaultValue: '사업자명' })}</div>
                        <div className='col-md-4 pt-2 pb-2 d-flex align-items-center'>
                            <input className="form-control border-0" type="text" value={DetailData.biz_name || ''} readOnly={true} />
                        </div>
                    </div>

                    <div className='row ms-0'>
                        <div className='col-md-2 bg-light d-flex align-items-center pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.사업자등록일자`, { defaultValue: '사업자등록일자' })}</div>
                        <div className='col-md-4 pt-2 pb-2'>
                            <input className="form-control border-0" type="text" value={moment(DetailData.biz_reg_date).format('DD/MM/YYYY') || ''} readOnly={true} />
                        </div>

                        <div className='col-md-2 bg-light d-flex align-items-center pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.사업자등록장소`, { defaultValue: '사업자등록장소' })}</div>
                        <div className='col-md-4 pt-2 pb-2 d-flex align-items-center'>
                            <input className="form-control border-0" type="text" value={DetailData.biz_reg_location || ''} readOnly={true} />
                        </div>
                    </div>

                    <div className='row ms-0'>
                        <div className='col-md-2 bg-light d-flex align-items-center pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.대표자ID`, { defaultValue: '대표자ID' })}</div>
                        <div className='col-md-4 pt-2 pb-2'>
                            <input className="form-control border-0" type="text" value={DetailData.biz_repr_id || ''} readOnly={true} />
                        </div>

                        <div className='col-md-2 bg-light d-flex align-items-center pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.상태`, { defaultValue: '상태' })}</div>
                        <div className='col-md-4 pt-2 pb-2 d-flex align-items-center'>
                            <input className="form-control border-0" type="text" value={DetailData.biz_status || ''} readOnly={true} />
                        </div>
                    </div>

                    <div className='row ms-0'>
                        <div className='col-md-2 bg-light d-flex align-items-center pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.적요`, { defaultValue: '적요' })}</div>
                        <div className='col-md-10 pt-2 pb-2'>
                            <input className="form-control border-0" type="text" value={DetailData.biz_remark || ''} readOnly={true} />
                        </div>
                    </div>

                    <div className='row ms-0'>
                        {isEditMode 
                            ? <div className='col-md-2 bg-white d-flex align-items-center fw-bold pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.지점 수정`, { defaultValue: '지점 수정' })}</div>
                            : <div className='col-md-2 bg-white d-flex align-items-center fw-bold pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.지점 등록`, { defaultValue: '지점 등록' })}</div>
                        }
                    </div>

                    <div className='row ms-0'>
                        {isEditMode
                            ? <div className='col-md-2 bg-light d-flex align-items-center pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.지점코드`, { defaultValue: '지점코드' })} {isEditMode ? null : <span className="text-danger">*</span>}</div>
                            : ''
                        }
                            {isEditMode 
                                ? <div className='col-md-4 pt-2 pb-2'>
                                    <input className="form-control border-0" type="text" value={formData.branch_code || ''} readOnly={isEditMode} />
                                </div>
                                : ''
                            }

                        <div className='col-md-2 bg-light d-flex align-items-center pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.지점명`, { defaultValue: '지점명' })} <span className="text-danger">*</span></div>
                        {isEditMode
                            ? <div className='col-md-4 pt-2 pb-2 d-flex align-items-center'>
                            <input type='text' className='form-control' value={formData.branch_name || ''} onChange={(e) => handleChange(e, 'branch_name')}></input>
                        </div>
                            : <div className='col-md-10 pt-2 pb-2 d-flex align-items-center'>
                                <input type='text' className='form-control' value={formData.branch_name || ''} onChange={(e) => handleChange(e, 'branch_name')}></input>
                            </div>
                        }
                    </div>

                    <div className='row ms-0'>
                        <div className='col-md-2 bg-light d-flex align-items-center pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.구분`, { defaultValue: '구분' })} <span className="text-danger">*</span></div>
                        <div className='col-md-4 pt-2 pb-2'>
                            <select className='form-select' id="branch_type" value={formData.branch_type} onChange={(e) => setFormData({ ...formData, branch_type: e.target.value })}>
                                <option value='HEAD'>본점</option>
                                <option value='BRANCH'>지점</option>
                            </select>
                        </div>

                        <div className='col-md-2 bg-light d-flex align-items-center pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.지점등록일자`, { defaultValue: '지점등록일자' })} <span className="text-danger">*</span></div>
                        <div className='col-md-4 pt-2 pb-2'>
                            <div className="input-group input-daterange">
                                <div className='col'>
                                    <DatePicker
                                        id='biz_reg_date'
                                        className='form-control'
                                        selected={formData.contract_date}
                                        onChange={(date) => handleChange(date, 'contract_date')}
                                        dateFormat="dd/MM/yyyy"
                                    />
                                    <div className="input-group-prepend position-absolute top-0 end-0 bottom-0 d-flex">
                                        <span className="input-group-text bg-none border-0">
                                            <i className='far fa-calendar' />
                                        </span>
                                    </div>
                                </div>														
                            </div>
                        </div>
                    </div>

                    <div className='row ms-0'>
                        <div className='col-md-2 bg-light d-flex align-items-center pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.지점매니저ID`, { defaultValue: '지점매니저ID' })} {isEditMode ? null : <span className="text-danger">*</span>}</div>
                        <div className='col-md-4 pt-2 pb-2'>
                            {isEditMode 
                                ? <input className="form-control border-0" type="text" value={formData.manager_id || ''} readOnly={isEditMode} />
                                : <input type='text' className='form-control' value={formData.manager_id || ''} onChange={(e) => handleChange(e, 'manager_id')}></input>
                            }
                        </div>

                        <div className='col-md-2 bg-light d-flex align-items-center pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.상태`, { defaultValue: '상태' })} <span className="text-danger">*</span></div>
                        <div className='col-md-4 pt-2 pb-2 d-flex align-items-center'>
                            <select className='form-select' id="branch_status" value={formData.branch_status} onChange={(e) => setFormData({ ...formData, branch_status: e.target.value })}>
                                <option value='NORMAL'>정상</option>
                                <option value='EXPIRED'>해지</option>
                            </select>
                        </div>
                    </div>

                    <div className='row ms-0'>
                        <div className='col-md-2 bg-light d-flex align-items-center pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.적요`, { defaultValue: '적요' })}</div>
                        <div className='col-md-10 pt-2 pb-2'>
                            <input type='text' className='form-control' value={formData.branch_remark || ''} onChange={(e) => handleChange(e, 'branch_remark')}></input>
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer className="d-flex justify-content-around">
                    <button className="btn btn-outline-gray me-1 mb-1 w-100px" onClick={handleClose}>
                        {t('COMMON.취소', { defaultValue: '취소' })}
                    </button>

                    <button className="btn btn-outline-primary me-1 mb-1 w-100px" onClick={handleSave}>
                        {isEditMode 
                            ? t('COMMON.수정', { defaultValue: '수정' })
                            : t('COMMON.저장', { defaultValue: '저장' })
                        }
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Contract001_02;