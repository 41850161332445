import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import CustomToast from '../../components/toast/toast.jsx';
import API from '../../api/api.jsx';

// 사업자등록 모달
const Contract001_01 = ({ showModal, setShowBizModal, handleSearch, DetailData }) => {
    const { t }                             = useTranslation();
    const [bizCheckError, setBizCheckError] = useState(0);

     // 토스트
	const [toast, setToast]               = useState(false);
	const [text, setText]                 = useState('');

    // 수정여부 체크
    const isEditMode         = DetailData && Object.keys(DetailData).length > 0;

    const initBiz_code       = isEditMode ? DetailData.biz_code : '' ;        // 사업자 코드
    const initBiz_no         = isEditMode ? DetailData.biz_no : '' ;          // 사업자 번호
    const initBiz_name       = isEditMode ? DetailData.biz_name : '' ;        // 사업자명 
    const initBiz_date       = isEditMode ? DetailData.biz_reg_date : '';     // 사업자등록일자
    const initBiz_location   = isEditMode ? DetailData.biz_reg_location : ''; // 사업자등록장소
    const initBiz_id         = isEditMode ? DetailData.biz_repr_id : '' ;     // 대표자ID
    const initBiz_status     = isEditMode ? (DetailData.biz_status === '정상' ? 'NORMAL' : (DetailData.biz_status === '해지' ? 'EXPIRED' : 'NORMAL')) : 'NORMAL'; // 상태
    const initUser_name      = isEditMode ? DetailData.name : '' ;            // 대표자 성명
    const initUser_phone     = isEditMode ? DetailData.phone_number : '' ;    // 대표자 전화번호
    const initBiz_remark     = isEditMode ? DetailData.biz_remark : '' ;      // 적요
    const initEcomit_token   = isEditMode ? DetailData.token : '' ;           // banking 토큰
    const initEcomit_private = isEditMode ? DetailData.private_key : '' ;     // banking private_key
    const initEcomit_public  = isEditMode ? DetailData.public_key : '' ;      // banking public_key
    const initZalo_secret    = isEditMode ? DetailData.secret_key : '' ;      // zalo secret_key
    const initZalo_refresh   = isEditMode ? DetailData.refresh_token : '' ;   // zalo refresh_token
    const initZalo_access    = isEditMode ? DetailData.access_token : '' ;    // zalo access_token
    const initZalo_app       = isEditMode ? DetailData.app_id : '' ;          // zalo app_id
    const initZalo_t01       = isEditMode ? DetailData.t01_template_id : '' ; // zalo OTP
    const initZalo_t02       = isEditMode ? DetailData.t02_template_id : '' ; // zalo 대출실행안내
    const initZalo_t03       = isEditMode ? DetailData.t03_template_id : '' ; // zalo 이자납입안내
    const initZalo_t04       = isEditMode ? DetailData.t04_template_id : '' ; // zalo 만기안내
    const initZalo_t05       = isEditMode ? DetailData.t05_template_id : '' ; // zalo 이자연체안내
    const initZalo_t06       = isEditMode ? DetailData.t06_template_id : '' ; // zalo 만기연체안내

    const [formData, setFormData] = useState({
        title            : '',
        biz_code         : '',
        biz_no           : '',
        biz_name         : '',
        biz_reg_date     : '',
        biz_reg_location : '',
        biz_repr_id      : '',
        biz_status       : '',
        name             : '',
        phone_number     : '',
        biz_remark       : '',
        token            : '',
        private_key      : '',
        public_key       : '',
        secret_key       : '',
        refresh_token    : '',
        access_token     : '',
        app_id           : '',
        t01_template_id  : '',
        t02_template_id  : '',
        t03_template_id  : '',
        t04_template_id  : '',
        t05_template_id  : '',
        t06_template_id  : '',
    });

    // 렌더링
    useEffect(() => {
        // 모달이 활성화 된 경우
        if (showModal) {
            setBizCheckError(0);

            setFormData({
                title            : 'biz',
                biz_code         : initBiz_code,
                biz_no           : initBiz_no,
                biz_name         : initBiz_name,
                biz_reg_date     : initBiz_date ? new Date(initBiz_date) : new Date(), // Convert to Date object
                biz_reg_location : initBiz_location,
                biz_repr_id      : initBiz_id,
                biz_status       : initBiz_status,
                name             : initUser_name,
                phone_number     : initUser_phone,
                biz_remark       : initBiz_remark,
                token            : initEcomit_token,
                private_key      : initEcomit_private,
                public_key       : initEcomit_public,
                secret_key       : initZalo_secret,
                refresh_token    : initZalo_refresh,
                access_token     : initZalo_access,
                app_id           : initZalo_app,
                t01_template_id  : initZalo_t01,
                t02_template_id  : initZalo_t02,
                t03_template_id  : initZalo_t03,
                t04_template_id  : initZalo_t04,
                t05_template_id  : initZalo_t05,
                t06_template_id  : initZalo_t06,
            });
        }
    }, [showModal, isEditMode, initBiz_code, initBiz_no, initBiz_name, initBiz_date, initBiz_location, initBiz_id, initBiz_status, initUser_name, initUser_phone, initBiz_remark, initEcomit_token, initEcomit_private, initEcomit_public, initZalo_secret, initZalo_refresh, initZalo_access, initZalo_app, initZalo_t01, initZalo_t02, initZalo_t03, initZalo_t04, initZalo_t05, initZalo_t06]);

    // 사업자번호 체크
    const handleBizCheck = async (e) => {
        e.preventDefault();

        if (!formData.biz_no) {
            alert('사업자 번호를 입력해주세요.');
            return;
        };

        try {
            const biz_no = formData.biz_no;

            const response = await API.checkBizNo(biz_no);

            const message = response.result.content.message;

            // 정상 처리 시 1
            setBizCheckError(1);

            alert(t(`COMMON.${message}`, { defaultValue: message }));

        } catch (error) {
            if (error.response && error.response.status === 409) {
                // 409 Conflict에 대한 에러 메시지를 화면에 표시
                const message = error.response.data.result.content.message;

                // 중복시 9
                setBizCheckError(9);

                alert(t(`COMMON.${message}`, { defaultValue: message }));
            };

            return;
        };
    };

    const handleChange = (e, id) => {
        // DatePicker로 날짜 변경시
        if (id === 'biz_reg_date') {
            setFormData((prevData) => ({ ...prevData, [id]: e }));
        } else {
            // 일반적인 input 필드 값 변경시
            const { value } = e.target;
            setFormData((prevData) => ({ ...prevData, [id]: value }));
        }
    };

    // 이메일 정규표현식
    const isEmailValid = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    // 저장 함수
    const handleSave = async (e) => {
        e.preventDefault();

        // 필수 항목들의 키 배열
        const requiredFields = [
            'biz_no',
            'biz_name',
            'biz_reg_date',
            'biz_reg_location',
            'biz_repr_id',
            'name',
            'phone_number',
            'token',
            'private_key',
            'public_key',
            'secret_key',
            'refresh_token',
            'access_token',
            'app_id',
            't01_template_id',
            't02_template_id',
            't03_template_id',
            't04_template_id',
            't05_template_id',
            't06_template_id',
        ];

        // 필수 항목 중 하나라도 값이 비어있으면 알림 띄우기
        for (const field of requiredFields) {
            // 수정인 경우에는 사업자번호, 사업자등록일자, 대표자ID는 수정이 불가하므로
            // 필수 값에서 제외
            if (isEditMode && ['biz_no', 'biz_reg_date', 'biz_repr_id'].includes(field)) {
                continue;
            }

            if (!formData[field]) {
                alert('필수 값이 입력되지 않았습니다.');
                return;
            }
        };

        // 등록시 대표자 ID는 email 형태
        if (!isEditMode && !isEmailValid(formData.biz_repr_id)) {
            alert('대표자ID는 email 형식입니다.');
            return;
        };

        if (!isEditMode && bizCheckError !== 1) {
            alert('사업자번호를 확인해주세요.');
            return;
        };

        try {
            const confirmationMessage = isEditMode ? '사업자를 수정하시겠습니까?' : '사업자를 등록하시겠습니까?';
            const confirmed           = window.confirm(confirmationMessage);

            if (confirmed) {
                // 추가 확인
                if (formData.biz_status === 'EXPIRED') {
                    const secondConfirmationMessage = '사업장이 해지되면 관련 지점도 함께 해지됩니다. 계속 진행하시겠습니까?';
                    const secondConfirmed = window.confirm(secondConfirmationMessage);

                    if (!secondConfirmed) {
                        return; // 사용자가 취소하면 종료
                    }
                }

                const savedData = await API.saveContractInfo({ formData });
                 // 생성 및 수정 로직
                if (savedData.code === 201 || savedData.code === 200) {
                    const message = savedData.result.content.message;

                    setText(message);
                    setToast(true);
                    handleClose();
                    return;
                }
            }

        } catch (error) {
            if(error.response.data.code === 409){
                alert(error.response.data.result.content.message)
            }else{
                alert(error.response.data.result.content.message)
            }
            
        };
    };

    // 닫기 함수
    const handleClose = () => {
        setFormData({
            title            : '',
            biz_code         : '',
            biz_no           : '',
            biz_name         : '',
            biz_reg_date     : '',
            biz_reg_location : '',
            biz_repr_id      : '',
            biz_status       : '',
            name             : '',
            phone_number     : '',
            biz_remark       : '',
            token            : '',
            private_key      : '',
            public_key       : '',
            secret_key       : '',
            refresh_token    : '',
            access_token     : '',
            app_id           : '',
            t01_template_id  : '',
            t02_template_id  : '',
            t03_template_id  : '',
            t04_template_id  : '',
            t05_template_id  : '',
            t06_template_id  : '',
        });

        setShowBizModal(false);

        handleSearch();
    };

    return (
        <>
            <CustomToast show={toast} setToast={setToast} text={text} />
            <Modal show={showModal} onHide={handleClose} centered dialogClassName="modal-lg" backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>
                        {isEditMode
                            ? t('STAFF_MANAGEMENT.사업자 수정', { defaultValue: '사업자 수정' })
                            : t('STAFF_MANAGEMENT.사업자 등록', { defaultValue: '사업자 등록' })
                        }
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body className="text-start">
                    <div className='row ms-0'>
                        <div className='col-md-2 bg-light d-flex align-items-center pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.사업자 번호`, { defaultValue: '사업자 번호' })} {isEditMode ? null : <span className="text-danger">*</span>}</div>
                        <div className='col-md-4 pt-2 pb-2'>
                            {isEditMode 
                                ? <input className="form-control border-0" type="text" value={formData.biz_no} readOnly={isEditMode} />
                                : <div className="position-relative">
                                    <input className={`form-control ${
                                        bizCheckError === 9 ? 'border-danger' : bizCheckError === 1 ? 'border-success' : ''
                                    }`} type="text" value={formData.biz_no || ''} 
                                        onInput={(e) => {
                                            e.target.value = e.target.value.replace(/[^0-9]/g, "").replace(/(\..*)\./g, "$1");
                                            handleChange(e, 'biz_no');
                                        }} 
                                        onChange={(e) => handleChange(e, 'biz_no')} />
                                        <div className="input-group-prepend position-absolute top-0 end-0 bottom-0 d-flex">
                                            <button className="input-group-text btn btn-success" onClick={handleBizCheck}>
                                                <i className = "fa-solid fa-spell-check"></i>
                                            </button>
                                        </div>
                                </div>
                            }
                        </div>

                        <div className='col-md-2 bg-light d-flex align-items-center pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.사업자명`, { defaultValue: '사업자명' })} <span className="text-danger">*</span></div>
                        <div className='col-md-4 pt-2 pb-2 d-flex align-items-center'>
                            <input type='text' className='form-control' value={formData.biz_name || ''} onChange={(e) => handleChange(e, 'biz_name')}></input>
                        </div>
                    </div>

                    <div className='row ms-0'>
                        <div className='col-md-2 bg-light d-flex align-items-center pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.사업자등록일자`, { defaultValue: '사업자등록일자' })} {isEditMode ? null : <span className="text-danger">*</span>}</div>
                        <div className='col-md-4 pt-2 pb-2'>
                            <div className="input-group input-daterange">
                                <div className='col'>
                                    <DatePicker
                                        id='biz_reg_date'
                                        className={isEditMode ? 'form-control border-0' : 'form-control'}
                                        selected={formData.biz_reg_date}
                                        onChange={(date) => handleChange(date, 'biz_reg_date')}
                                        dateFormat="dd/MM/yyyy"
                                        readOnly={isEditMode}
                                    />
                                    <div className="input-group-prepend position-absolute top-0 end-0 bottom-0 d-flex">
                                        <span className="input-group-text bg-none border-0">
                                            <i className='far fa-calendar' />
                                        </span>
                                    </div>
                                </div>														
                            </div>
                        </div>

                        <div className='col-md-2 bg-light d-flex align-items-center pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.사업자등록장소`, { defaultValue: '사업자등록장소' })} <span className="text-danger">*</span></div>
                        <div className='col-md-4 pt-2 pb-2 d-flex align-items-center'>
                            <input type='text' id="biz_reg_location" value={formData.biz_reg_location || ''} onChange={(e) => handleChange(e, 'biz_reg_location')} className='form-control'></input>
                        </div>
                    </div>

                    <div className='row ms-0'>
                        <div className='col-md-2 bg-light d-flex align-items-center pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.대표자ID`, { defaultValue: '대표자ID' })} {isEditMode ? null : <span className="text-danger">*</span>}</div>
                        <div className='col-md-4 pt-2 pb-2'>
                            {isEditMode 
                                ? <input className="form-control border-0" type="text" value={formData.biz_repr_id || ''} readOnly={isEditMode} />
                                : <input type='text' className='form-control' value={formData.biz_repr_id || ''} onChange={(e) => handleChange(e, 'biz_repr_id')}></input>
                            }
                        </div>

                        <div className='col-md-2 bg-light d-flex align-items-center pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.상태`, { defaultValue: '상태' })} <span className="text-danger">*</span></div>
                        <div className='col-md-4 pt-2 pb-2 d-flex align-items-center'>
                            <select className='form-select' id="biz_status" value={formData.biz_status} onChange={(e) => setFormData({ ...formData, biz_status: e.target.value })}>
                                <option value='NORMAL'>정상</option>
                                <option value='EXPIRED'>해지</option>
                            </select>
                        </div>
                    </div>

                    <div className='row ms-0'>
                        <div className='col-md-2 bg-light d-flex align-items-center pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.대표자 성명`, { defaultValue: '대표자 성명' })} <span className="text-danger">*</span></div>
                        <div className='col-md-4 pt-2 pb-2'>
                            <input type='text' id="name" value={formData.name || ''} onChange={(e) => handleChange(e, 'name')} className='form-control'></input>
                        </div>

                        <div className='col-md-2 bg-light d-flex align-items-center pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.대표자 전화번호`, { defaultValue: '대표자 전화번호' })} <span className="text-danger">*</span></div>
                        <div className='col-md-4 pt-2 pb-2 d-flex align-items-center'>
                            <input type='text' id="phone_number" value={formData.phone_number || ''} onChange={(e) => handleChange(e, 'phone_number')} className='form-control'></input>
                        </div>
                    </div>

                    <div className='row ms-0'>
                        <div className='col-md-2 bg-light d-flex align-items-center pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.적요`, { defaultValue: '적요' })}</div>
                        <div className='col-md-10 pt-2 pb-2'>
                            <input type='text' value={formData.biz_remark || ''} onChange={(e) => handleChange(e, 'biz_remark')} className='form-control'></input>
                        </div>
                    </div>

                    <div className='row ms-0'>
                        <div className='col-md-2 bg-light d-flex align-items-center pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.TOKEN`, { defaultValue: 'TOKEN' })} <span className="text-danger">*</span></div>
                        <div className='col-md-10 pt-2 pb-2'>
                            <input className="form-control" type="text" value={formData.token || ''} onChange={(e) => handleChange(e, 'token')} />
                        </div>
                    </div>

                    <div className='row ms-0'>
                        <div className='col-md-2 bg-light d-flex align-items-center pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.PRIVATE_KEY`, { defaultValue: 'PRIVATE_KEY' })} <span className="text-danger">*</span></div>
                        <div className='col-md-10 pt-2 pb-2'>
                            <input className="form-control" type="text" value={formData.private_key || ''} onChange={(e) => handleChange(e, 'private_key')} />
                        </div>
                    </div>

                    <div className='row ms-0'>
                        <div className='col-md-2 bg-light d-flex align-items-center pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.PUBLIC_KEY`, { defaultValue: 'PUBLIC_KEY' })} <span className="text-danger">*</span></div>
                        <div className='col-md-10 pt-2 pb-2'>
                            <input className="form-control" type="text" value={formData.public_key || ''} onChange={(e) => handleChange(e, 'public_key')} />
                        </div>
                    </div>

                    <div className='row ms-0'>
                        {isEditMode 
                            ? <div className='col-md-2 bg-white d-flex align-items-center fw-bold pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.ZALO 정보 수정`, { defaultValue: 'ZALO 정보 수정' })}</div>
                            : <div className='col-md-2 bg-white d-flex align-items-center fw-bold pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.ZALO 정보 등록`, { defaultValue: 'ZALO 정보 등록' })}</div>
                        }
                    </div>

                    <div className='row ms-0'>
                        <div className='col-md-2 bg-light d-flex align-items-center pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.SECRET_KEY`, { defaultValue: 'SECRET_KEY' })} <span className="text-danger">*</span></div>
                        <div className='col-md-10 pt-2 pb-2'>
                            <input className="form-control" type="text" value={formData.secret_key || ''} onChange={(e) => handleChange(e, 'secret_key')} />
                        </div>
                    </div>

                    <div className='row ms-0'>
                        <div className='col-md-2 bg-light d-flex align-items-center pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.REFRESH_TOKEN`, { defaultValue: 'REFRESH_TOKEN' })} <span className="text-danger">*</span></div>
                        <div className='col-md-10 pt-2 pb-2'>
                            <input className="form-control" type="text" value={formData.refresh_token || ''} onChange={(e) => handleChange(e, 'refresh_token')} />
                        </div>
                    </div>

                    <div className='row ms-0'>
                        <div className='col-md-2 bg-light d-flex align-items-center pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.ACCESS_TOKEN`, { defaultValue: 'ACCESS_TOKEN' })} <span className="text-danger">*</span></div>
                        <div className='col-md-10 pt-2 pb-2'>
                            <input className="form-control" type="text" value={formData.access_token || ''} onChange={(e) => handleChange(e, 'access_token')} />
                        </div>
                    </div>

                    <div className='row ms-0'>
                        <div className='col-md-2 bg-light d-flex align-items-center pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.APP_ID`, { defaultValue: 'APP_ID' })} <span className="text-danger">*</span></div>
                        <div className='col-md-10 pt-2 pb-2'>
                            <input className="form-control" type="text" value={formData.app_id || ''} onChange={(e) => handleChange(e, 'app_id')} />
                        </div>
                    </div>

                    <div className='row ms-0'>
                        <div className='col-md-2 d-flex align-items-center pt-2 pb-2' style={{ backgroundColor : '#D6E3BC'}}>{t(`LOAN_RECEIVABLE_INQUIRY.로그인`, { defaultValue: '로그인' })} <span className="text-danger">*</span></div>
                        <div className='col-md-4 pt-2 pb-2'>
                            {isEditMode 
                                ? <input className="form-control" type="text" value={formData.t01_template_id || ''} onChange={(e) => handleChange(e, 't01_template_id')} />
                                : <input type='text' className='form-control' value={formData.t01_template_id || ''} onChange={(e) => handleChange(e, 't01_template_id')}></input>
                            }
                        </div>

                        <div className='col-md-2 d-flex align-items-center pt-2 pb-2' style={{ backgroundColor : '#D6E3BC'}}>{t(`LOAN_RECEIVABLE_INQUIRY.대출실행안내`, { defaultValue: '대출실행안내' })} <span className="text-danger">*</span></div>
                        <div className='col-md-4 pt-2 pb-2 d-flex align-items-center'>
                            {isEditMode 
                                ? <input className="form-control" type="text" value={formData.t02_template_id || ''} onChange={(e) => handleChange(e, 't02_template_id')} />
                                : <input type='text' className='form-control' value={formData.t02_template_id || ''} onChange={(e) => handleChange(e, 't02_template_id')}></input>
                            }
                        </div>
                    </div>

                    <div className='row ms-0'>
                        <div className='col-md-2 d-flex align-items-center pt-2 pb-2' style={{ backgroundColor : '#D6E3BC'}}>{t(`LOAN_RECEIVABLE_INQUIRY.이자납입안내`, { defaultValue: '이자납입안내' })} <span className="text-danger">*</span></div>
                        <div className='col-md-4 pt-2 pb-2'>
                            {isEditMode 
                                ? <input className="form-control" type="text" value={formData.t03_template_id || ''} onChange={(e) => handleChange(e, 't03_template_id')} />
                                : <input type='text' className='form-control' value={formData.t03_template_id || ''} onChange={(e) => handleChange(e, 't03_template_id')}></input>
                            }
                        </div>

                        <div className='col-md-2 d-flex align-items-center pt-2 pb-2' style={{ backgroundColor : '#D6E3BC'}}>{t(`LOAN_RECEIVABLE_INQUIRY.만기안내`, { defaultValue: '만기안내' })} <span className="text-danger">*</span></div>
                        <div className='col-md-4 pt-2 pb-2 d-flex align-items-center'>
                            {isEditMode 
                                ? <input className="form-control" type="text" value={formData.t04_template_id || ''} onChange={(e) => handleChange(e, 't04_template_id')} />
                                : <input type='text' className='form-control' value={formData.t04_template_id || ''} onChange={(e) => handleChange(e, 't04_template_id')}></input>
                            }
                        </div>
                    </div>

                    <div className='row ms-0'>
                        <div className='col-md-2 d-flex align-items-center pt-2 pb-2' style={{ backgroundColor : '#D6E3BC'}}>{t(`LOAN_RECEIVABLE_INQUIRY.이자연체안내`, { defaultValue: '이자연체안내' })} <span className="text-danger">*</span></div>
                        <div className='col-md-4 pt-2 pb-2'>
                            {isEditMode 
                                ? <input className="form-control" type="text" value={formData.t05_template_id || ''} onChange={(e) => handleChange(e, 't05_template_id')} />
                                : <input type='text' className='form-control' value={formData.t05_template_id || ''} onChange={(e) => handleChange(e, 't05_template_id')}></input>
                            }
                        </div>

                        <div className='col-md-2 d-flex align-items-center pt-2 pb-2' style={{ backgroundColor : '#D6E3BC'}}>{t(`LOAN_RECEIVABLE_INQUIRY.만기연체안내`, { defaultValue: '만기연체안내' })} <span className="text-danger">*</span></div>
                        <div className='col-md-4 pt-2 pb-2 d-flex align-items-center'>
                            {isEditMode 
                                ? <input className="form-control" type="text" value={formData.t06_template_id || ''} onChange={(e) => handleChange(e, 't06_template_id')} />
                                : <input type='text' className='form-control' value={formData.t06_template_id || ''} onChange={(e) => handleChange(e, 't06_template_id')}></input>
                            }
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer className="d-flex justify-content-around">
                    <button className="btn btn-outline-gray me-1 mb-1 w-100px" onClick={handleClose}>
                        {t('COMMON.취소', { defaultValue: '취소' })}
                    </button>

                    <button className="btn btn-outline-primary me-1 mb-1 w-100px" onClick={handleSave}>
                        {isEditMode 
                            ? t('COMMON.수정', { defaultValue: '수정' })
                            : t('COMMON.저장', { defaultValue: '저장' })
                        }
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Contract001_01;