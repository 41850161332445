import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getUserInfo } from '../../reducers/selectors/selectors.jsx';
import store, { persistor } from '../../store.jsx';

const SidebarProfile = () => {
	const { t }                       = useTranslation();
	const userInfo                    = getUserInfo(store.getState());
	const [name, setName]             = useState('');
	const [userId, setUserId]         = useState('');
	const [authority, setAuthority]   = useState('');
	const [branchName, setBranchName] = useState('');

	useEffect(() => {
		if (userInfo) {
			setName(userInfo.name);
			setUserId(userInfo.user_id.split('@')[0]); // @ 을 기준으로 앞에 부분만
			setAuthority(userInfo.authority);
			setBranchName(userInfo.branch_name);
		}
	}, [userInfo]);

	return (
		// <AppSettings.Consumer>
		// 	{({appSidebarMinify}) => (
				<div className="menu">
					<div className="menu-profile">
						<div className="menu-profile-cover with-shadow"></div>
						<div className="menu-profile-image">
							<img src="../assets/img/user/user-13.jpg" alt="" />
						</div>
						<div className="menu-profile-info">
							<div className="d-flex align-items-center">
								<div className="flex-grow-1">
									{name}<span style={{ fontSize: '12px' }}>({userId})</span>
								</div>
							</div>
							<small>{t(`AUTHORITY.${authority}`, { defaultValue: authority })}</small>
							<small>{branchName}</small>
						</div>
					</div>
				</div>
			// )}
		// </AppSettings.Consumer>
	)
};

export default SidebarProfile;