import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import i18next from "i18next";
import DropdownProfile from './dropdown/profile.jsx';
import logo from '../../assets/img/logo.png';
import { getLangCodeInfo } from '../../reducers/selectors/selectors.jsx';
import store, { persistor } from '../../store.jsx';
import { AppSettings } from './../../config/app-settings.js';

const Header = () => {
	const {
		toggleAppSidebarMobile,
		toggleAppSidebarEnd,
		toggleAppSidebarEndMobile,
		toggleAppTopMenuMobile,
		appHeaderLanguageBar,
		appHeaderMegaMenu,
		appHeaderInverse,
		appSidebarTwo,
		appTopMenu,
		appSidebarNone
	} = useContext(AppSettings);

	const [selectedLanguage, setSelectedLanguage] = useState('');

	useEffect(() => {
		// localStorage에서 langCodeInfo 가져오기
		const langCodeInfoString = localStorage.getItem('langCodeInfo');

		// JSON 문자열을 JavaScript 객체로 변환
		const langCodeInfo = JSON.parse(langCodeInfoString);
		setSelectedLanguage(langCodeInfo);
		
		// Redux 상태 변경을 감지하는 리스너 등록
		const unsubscribe = store.subscribe(() => {
			const langCode = getLangCodeInfo(store.getState());
			if (langCode) {
				setSelectedLanguage(langCode);
				i18next.changeLanguage(langCode);
			}
		});

		// 컴포넌트 언마운트 시 리스너 제거
		return () => unsubscribe();
	}, []); 

	// 버튼 클릭시
	const handleLanguageChange = (selectedLanguage) => {
		// 헤더의 언어를 변경 시 a 태그로 페이지가 이동이 되더라도 언어가 동기화 되게 수정
		localStorage.setItem('langCodeInfo', JSON.stringify(selectedLanguage));
		i18next.changeLanguage(selectedLanguage);
		setSelectedLanguage(selectedLanguage);
	};

	return (
		<div id="header" className={'app-header ' + (appHeaderInverse ? 'app-header-inverse' : '')}>
			<div className="navbar-header">
				{appSidebarTwo && (
					<button type="button" className="navbar-mobile-toggler" onClick={toggleAppSidebarEndMobile}>
						<span className="icon-bar"></span>
						<span className="icon-bar"></span>
						<span className="icon-bar"></span>
					</button>
				)}
				<Link to="/" className="navbar-brand">
					<Image src={logo} alt='DIDO'></Image>
				</Link>
				
				{appHeaderMegaMenu && (
					<button type="button" className="navbar-mobile-toggler" data-bs-toggle="collapse" data-bs-target="#top-navbar">
						<span className="fa-stack fa-lg text-inverse">
							<i className="far fa-square fa-stack-2x"></i>
							<i className="fa fa-cog fa-stack-1x"></i>
						</span>
					</button>
				)}
				{appTopMenu && !appSidebarNone && (
				<button type="button" className="navbar-mobile-toggler" onClick={toggleAppTopMenuMobile}>
					<span className="fa-stack fa-lg text-inverse">
						<i className="far fa-square fa-stack-2x"></i>
						<i className="fa fa-cog fa-stack-1x"></i>
					</span>
				</button>
				)}
				{appSidebarNone && appTopMenu && (
					<button type="button" className="navbar-mobile-toggler" onClick={toggleAppTopMenuMobile}>
						<span className="icon-bar"></span>
						<span className="icon-bar"></span>
						<span className="icon-bar"></span>
					</button>
				)}
				{!appSidebarNone && (
					<button type="button" className="navbar-mobile-toggler" onClick={toggleAppSidebarMobile}>
						<span className="icon-bar"></span>
						<span className="icon-bar"></span>
						<span className="icon-bar"></span>
					</button>
				)}
			</div>
			
			<div className="navbar-nav">
				{/* 다국어 필요 시 해당 부분 사용 */}
				{/* <select
					onChange={(e) => handleLanguageChange(e.target.value)} // Add a function to handle language change
					className="form-select form-select-sm"
					style={{ width: '118px' }}
					value={selectedLanguage}
				>
					<option value="ko">🇰🇷</option>
					<option value="vi">🇻🇳</option>
					<option value="en">🇺🇸</option>
				</select> */}
				
				<DropdownProfile />
				
				{appSidebarTwo && (
					<div className="navbar-divider d-none d-md-block"></div>
				)}
				
				{appSidebarTwo && (
					<div className="navbar-item d-none d-md-block">
						<Link to="/" onClick={toggleAppSidebarEnd} className="navbar-link icon">
							<i className="fa fa-th"></i>
						</Link>
					</div>
				)}
			</div>
		</div>
	)
};

export default Header;