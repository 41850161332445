import React, {useEffect, useState} from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';

import App from './../app.jsx';
import Login from '../pages/user/login.jsx';
import Contract001 from '../pages/contract/contract_001.jsx';       // 계약관리
import Statistics001 from '../pages/statistics/statistics_001.jsx'; // 대출통계(일별)
import Statistics002 from '../pages/statistics/statistics_002.jsx'; // SNS통계(일별)
import Statistics003 from '../pages/statistics/statistics_003.jsx'; // 수납/이체 통계(일별)
import Statistics004 from '../pages/statistics/statistics_004.jsx'; // 수수료

// 유저 정보
import { getUserInfo } from "../reducers/selectors/selectors.jsx";
import store, { persistor } from "../store.jsx";

const AppRoute = () => {
	const navigate = useNavigate();
	const [userInfoChecked, setUserInfoChecked] = useState(false);

	// URL이 루트나 공백인 경우 대출접수현황 화면으로 자동 이동되게 설정
	useEffect(() => {
		const checkUserInfo = async () => {
			const userInfo = await getUserInfo(store.getState());

			if (!userInfo) {
				navigate('/login', { replace: true });
			}

			setUserInfoChecked(true);
		};

		if (!userInfoChecked) {
			checkUserInfo();
		}

		const isUrlEmpty = window.location.pathname === '/' || window.location.pathname === '';

		if (isUrlEmpty) {
			navigate('/contract/contract_001', { replace: true });
		}
	}, [navigate, userInfoChecked]);

	if (!userInfoChecked) {
		// 사용자 정보 확인 중일 때 로딩 상태나 스피너를 반환하여 초기 로드 중에 다른 화면이 잠깐 나타나지 않도록 합니다.
		return;
	}

	return (
		<Routes>
			<Route path="" element={<App />}>
				<Route path="/login" element={<Login />} />
				<Route path="/contract/contract_001" element={<Contract001 />} />
				<Route path="/statistics/statistics_001" element={<Statistics001 />} />
				<Route path="/statistics/statistics_002" element={<Statistics002 />} />
				<Route path="/statistics/statistics_003" element={<Statistics003 />} />
				<Route path="/statistics/statistics_004" element={<Statistics004 />} />
			</Route>
		</Routes>
	);
};

export default AppRoute;