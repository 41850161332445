import React, { useEffect } from 'react';
import { useResolvedPath, useMatch, NavLink, useLocation, matchPath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Menu from '../../config/app-menu.jsx';

function NavItem({ menu, ...props }) {
	const { t }  = useTranslation();
	let resolved = useResolvedPath(menu.path);
	let match    = useMatch({ path: resolved.pathname });
	let location = useLocation();
	let match2   = matchPath({path: resolved.pathname, end: false, },location.pathname);
	
	let icon      = menu.icon && <div className="menu-icon"><i className={menu.icon}></i></div>;
	let img       = menu.img && <div className="menu-icon-img"><img src={menu.img} alt="" /></div>;
	let caret     = (menu.children && !menu.badge) && <div className="menu-caret"></div>;
	let label     = menu.label && <span className="menu-label ms-5px">{menu.label}</span>;
	let badge     = menu.badge && <div className="menu-badge">{menu.badge}</div>;
	let highlight = menu.highlight && <i className="fa fa-paper-plane text-theme"></i>;
	let title     = menu.title && <div className="menu-text">{t(`MENU_I18N.${menu.title}`, { defaultValue: menu.title })} {label} {highlight}</div>;

	const handleClick = (e) => {
		if (menu.children && menu.children.length > 0) {
			e.preventDefault();
		}
	};

	return (
		<div className={'menu-item' + ((match || match2) ? ' active' : '') + (menu.children ? ' has-sub' : '')}>
			<NavLink className="menu-link" to={menu.path} {...props} onClick={handleClick} exact="true">
				{ img } { icon } { title }{ caret } { badge }
			</NavLink>
			
			{menu.children && (
				<div className="menu-submenu">
					{menu.children.map((submenu, i) => (
						<NavItem key={i} menu={submenu} />
					))}
				</div>
			)}
		</div>
	);
}

const SidebarNav = () => {
	const { t, i18n } = useTranslation();

	useEffect(() => {
	}, [i18n.language, t]);

	return (
		<div className="menu">
			<div className="menu-header">Navigation</div>
			{Menu.map((menu, i) => (
				<NavItem key={i} menu={menu} />
			))}
		</div>
	);
};

export default SidebarNav;