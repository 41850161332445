import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Card } from 'react-bootstrap';
import { AppSettings } from '../../config/app-settings.js';
import { setError, setLoading, setUserInfo, setRememberMe } from '../../reducers/userReducers.jsx';
import { setLangCodeInfo } from '../../reducers/langCodeReducers.jsx';
import { initializeI18n } from '../../i18n.jsx';
import API from '../../api/api.jsx';

// 로그인 화면
const Login = () => {
	const context                         = React.useContext(AppSettings);
	const navigate                        = useNavigate();
	const dispatch                        = useDispatch();
	const rememberMe                      = useSelector(state => state.login.rememberMe);

	// 로그인 정보
	const [userId, setUserId]             = useState('');
	const [password, setPassword]         = useState('');

	// 알림창
	const [showAlert, setShowAlert]       = useState(false);
	const [alertMessage, setAlertMessage] = useState('');

	useEffect(() => {
		context.handleSetAppSidebarNone(true);
		context.handleSetAppHeaderNone(true);
		context.handleSetAppFooterNone(true);
		context.handleSetAppThemeNone(true);
		context.handleSetAppContentClass('p-0');

		// 세션 스토리지에서 아이디 불러오기
        const storedUserId = sessionStorage.getItem('userId');
        if (storedUserId) {
            setUserId(storedUserId);
        }
	
		return () => {
			context.handleSetAppSidebarNone(false);
			context.handleSetAppHeaderNone(false);
			context.handleSetAppFooterNone(false);
			context.handleSetAppThemeNone(false);
			context.handleSetAppContentClass('');
		};
	}, []);

	// 아이디 저장 체크박스
	const handleRememberMeChange = () => {
		const newRememberMe = !rememberMe;

		// 세션 스토리지에 아이디 저장 또는 삭제
		if (newRememberMe) {
            sessionStorage.setItem('userId', userId);
        } else {
            sessionStorage.removeItem('userId');
        }

        dispatch(setRememberMe(newRememberMe));
	};

	const handleChangeUserId = (e) => {
		// 아이디가 변경될 때 세션 스토리지에 저장
        const newUserId = e.target.value;
        setUserId(newUserId);

        // 체크박스가 활성화된 경우에만 저장
        if (rememberMe) {
            sessionStorage.setItem('userId', newUserId);
        }
	};

	const handleChangePassword = (e) => {
		setPassword(e.target.value);
	};
	
	const handleSubmit = async (event) => {
		event.preventDefault();
	
		dispatch(setLoading(true));

		// API 호출 부분
		try {
			const response = await API.fetchLogin({
				userId,
				password
			});

			dispatch(setUserInfo(response));
			dispatch(setLangCodeInfo(response.lang_code));
			initializeI18n();

			navigate('/contract/contract_001');
		} catch (error) {
			// 인증 관련 에러 발생
			if (error.response.status === 401) {
				setAlertMessage(error.response.data.result.content.message);
				setShowAlert(true);
			
				// 일정 시간 후에 경고를 숨기도록 설정 (예: 3초 후)
				setTimeout(() => {
					setShowAlert(false);
				}, 3000);
				return;
			}

			dispatch(setError(error));
		}
	};

	return (
		<React.Fragment>
			<Card className='border-0'>
				<Card.Header>
					DIDO ADMIN
				</Card.Header>

				<div className="container">
					<div className="left-section"></div>
					<div className="right-section">
						<div className="login-container">
							<h2 className="login-title"><i className="fas fa-user-circle"></i>DIDO ADMIN</h2>

							<form onSubmit={handleSubmit}>
								<div className="form-control position-relative border-0">
									<i className="fas fa-user icon"></i>
									<input type="text" placeholder="아이디를 입력해주세요" id="text" value={userId} onChange={handleChangeUserId}/>
								</div>

								<div className="form-control position-relative border-0">
									<i className="fas fa-lock icon"></i>
									<input type="password" placeholder="비밀번호를 입력해주세요" id="password" value={password} onChange={handleChangePassword}/>
								</div>

								<div className="remember-me ms-3 mt-2">
									<input 
										type="checkbox" 
										id="rememberMe" 
										className="checkbox-input"
										checked={rememberMe}
										onChange={handleRememberMeChange}
									/>
									<span className="checkbox-custom"></span>
									<label htmlFor="rememberMe">아이디 저장</label>
								</div>

								{/* 커스텀 alert */}
								{showAlert && (
									<div className="alert alert-warning" role="alert" style={{ position: 'fixed', top: '20%', left: '50%', transform: 'translateX(-50%)', zIndex: '9999' }}>
										{alertMessage}
									</div>
								)}
							
								<button className='login-btn' type="submit">로그인</button>
							</form>
						</div>
					</div>
				</div>

				<Card.Footer style={{ backgroundColor : '#DEE2E6'}}>
					<p>Copyright &copy; DIDO. All Rights Reserved.</p>
				</Card.Footer>
			</Card>
		</React.Fragment>
	)
};

export default Login;