import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button } from 'react-bootstrap';
import API from '../../../api/api.jsx';

// 비밀번호 변경 모달
const Profile_001 = ({ showModal, setShowModal, userInfo }) => {
    const { t }                                               = useTranslation();
    const [passwordVisible, setPasswordVisible]               = useState(false);
    const [newPasswordVisible, setNewPasswordVisible]         = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

    // 현재 비밀번호
    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    // 신규 비밀번호
    const toggleNewPasswordVisibility = () => {
        setNewPasswordVisible(!newPasswordVisible);
    };

    // 신규 비밀번호 확인
    const toggleConfirmPasswordVisibility = () => {
        setConfirmPasswordVisible(!confirmPasswordVisible);
    };

    // staffInfo 상태값 초기화
	const initialStaffInfo = {
		user_id          : userInfo.user_id,
        password         : '',
        new_password     : '',
        confirm_password : '',
	};

	// 직원 정보
	const [staffInfo, setStaffInfo] = useState(initialStaffInfo);

    // PasswordInput
    const PasswordInput = ({ type = 'password', className, value, readOnly, onChange }) => (
        <input type={type} className={`form-control ${className}`} value={value} readOnly={readOnly} onChange={onChange} />
    );

    const renderPasswordInput = useMemo(() => (labelKey, position = '', type, value  = "", field = '', attr = false, edit = false, onToggleVisibility, isVisible) => (
        <>
            <div className='col-md-4 bg-light d-flex align-items-center pt-2 pb-2'>{labelKey} <span className="text-danger">*</span></div>
            <div className={`col-md-8 pt-2 pb-2 ${position}`}>
                {isVisible ? (
                    <PasswordInput type='text' value ={value} readOnly={attr} onChange={(e) => handleInputChange(field, e.target.value)} isVisible={isVisible} />
                ) : (
                    <PasswordInput type={type} value ={value} readOnly={attr} onChange={(e) => handleInputChange(field, e.target.value)} isVisible={isVisible} />
                )}
                {edit && (
                    <div className="input-group-prepend position-absolute top-0 end-0 bottom-0 d-flex">
                        <Button className="input-group-text bg-none border-0 pe-3" onClick={onToggleVisibility}>
                            {isVisible ? (
                                <i className="fa-solid fa-eye-slash"></i>
                            ) : (
                                <i className="fa-solid fa-eye"></i>
                            )}
                        </Button>
                    </div>
                )}
            </div>
        </>
    ), [passwordVisible, newPasswordVisible, confirmPasswordVisible]);

    // 입력 필드 값 변경 핸들러
	const handleInputChange = useCallback((field, value) => {
		setStaffInfo((prevInfo) => ({
		...prevInfo,
		[field]: value,
		}));
	}, [setStaffInfo]);

    // 수정 버튼 액션
    const handleEditData = () => {
        const profilePost = async () => {
			try {
				await API.editPassword({staffInfo});

                alert('비밀번호가 변경되었습니다.');

                handleClose();

			} catch (error) {
				console.error('Error Edit Profile data:', error);
                
                // 현재 비밀번호가 틀린 경우
                if (error.response.status === 401) {
                    alert('비밀번호를 다시 한번 확인해주세요.');
                    return;
                };

                // 500 에러
                if (error.response.status === 500) {
                    alert('오류가 발생했습니다. 관리자에게 문의하세요');
                    return;
                };
			}
		};

        if (!staffInfo.password) {
            alert('현재 비밀번호를 입력해주세요.');
            return;
        }

        if (!staffInfo.new_password) {
            alert('신규 비밀번호를 입력해주세요.');
            return;
        }

        if (!staffInfo.confirm_password) {
            alert('신규 비밀번호 확인을 입력해주세요.');
            return;
        }
        
        if (staffInfo.new_password !== staffInfo.confirm_password) {
            alert('신규 비밀번호를 다시 한번 확인해주세요.');
            return;
        }

        profilePost();
    };

    // 모달 닫기
    const handleClose = () => {		
        setShowModal(false);
	};

    return (
        <Modal show={showModal} onHide={handleClose} centered backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{t(`ACCOUNT_INFO.비밀번호 변경`, { defaultValue: '비밀번호 변경' })}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className='row ms-0'>
                    {/* 비밀번호변경 */}
                    {renderPasswordInput(t(`ACCOUNT_INFO.현재 비밀번호`, { defaultValue: '현재 비밀번호' }), 'position-relative', 'password', staffInfo.password, 'password', '', 'true', togglePasswordVisibility, passwordVisible)}

                    {renderPasswordInput(t(`ACCOUNT_INFO.신규 비밀번호`, { defaultValue: '신규 비밀번호' }), 'position-relative', 'password', staffInfo.new_password, 'new_password', '', 'true', toggleNewPasswordVisibility, newPasswordVisible)}

                    {renderPasswordInput(t(`ACCOUNT_INFO.신규 비밀번호 확인`, { defaultValue: '신규 비밀번호 확인' }), 'position-relative', 'password', staffInfo.confirm_password, 'confirm_password', '', 'true', toggleConfirmPasswordVisibility, confirmPasswordVisible)}
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="success" onClick={handleEditData}>{t(`ACCOUNT_INFORMATION.수정`, { defaultValue: '수정' })}</Button>
                <Button variant="secondary" onClick={handleClose}>{t(`ACCOUNT_INFORMATION.취소`, { defaultValue: '취소' })}</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default Profile_001;