import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './store.jsx';
import i18next from './i18n.jsx';
import AppRoute from './config/app-route.jsx';

// bootstrap
import 'bootstrap';

// css
import '@fortawesome/fontawesome-free/css/all.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './index.css';
import './scss/react.scss';

const container = document.getElementById('root');
const root      = createRoot(container);

const App = () => {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <I18nextProvider i18n={i18next}>
                    <BrowserRouter>
                        <AppRoute />
                    </BrowserRouter>
                </I18nextProvider>
            </PersistGate>
        </Provider>
    );
};

root.render(<App />);