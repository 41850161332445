import React, { useEffect } from 'react';
import { Toast } from 'react-bootstrap';
import '../../scss/toast/toast.scss';

const CustomToast = ({ show, text, setToast }) => {
    // 표시되는 시간
    const duration = 2000;

    useEffect(() => {
        let timer;

        if (show) {
            timer = setTimeout(() => {
                setToast(false);
            }, duration);
        }

        return () => {
            clearTimeout(timer);
        };
    }, [show, setToast, duration]);

    return (
        <Toast
            className={show ? 'toast-fade-in' : 'toast-fade-out'}
            style={{
                position: 'fixed',
                top: '10px',
                right: '10px',
                zIndex: 9999,
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                color: 'white',
            }}
            show={show}
            onClose={() => setToast(false)}
        >

        <Toast.Body>{text}</Toast.Body>
    </Toast>
    );
};

export default CustomToast;