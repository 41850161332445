import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactTabulator } from 'react-tabulator';
import DatePicker from "react-datepicker";
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { jwtDecode } from 'jwt-decode';
import "react-datepicker/dist/react-datepicker.css";
import loader from '../../assets/img/loading.gif'
import Contract001_01 from './contract_001_01.jsx';
import Contract001_02 from './contract_001_02.jsx';
import Contract001_02_01 from './contract_001_02_01.jsx';
import Contract001_03 from './contract_001_03.jsx';
import { API_BASE_URL } from '../../config/app-axios.jsx';
import { clearUserState } from '../../reducers/userReducers.jsx';
import { clearLangCodeState } from '../../reducers/langCodeReducers.jsx';

// 계약관리 화면
const Contract001 = () => {
	const { t, i18n } = useTranslation();
	const dispatch    = useDispatch();
	const defaultDate = new Date();
	const tableRef    = useRef(null);

	// 초기 값
	const [loading, setLoading]                       = useState(false);
	const [startDate, setStartDate]                   = useState(defaultDate.setMonth(defaultDate.getMonth() - 1));
	const [endDate, setEndDate]                       = useState(new Date());

	// 사업자번호 & 사업자명
	const [bizActiveField, setBizActiveField]         = useState('biz_no');
	const [bizActiveValue, setBizActiveValue]         = useState('');
	const bizActiveFieldRef                           = useRef(bizActiveField);
	const bizActiveValueRef                           = useRef(bizActiveValue);

	// 모달폼오픈
	const [showBizModal, setShowBizModal]               = useState(false);
	const [showBranchModal, setShowBranchModal]         = useState(false);
	const [showBranchEditModal, setShowBranchEditModal] = useState(false);
	const [showFeeModal, setShowFeeModal]               = useState(false);

	const [detailData, setDetailData]                 = useState([]);
	const [modify, setModify]                         = useState(false);
	const [rowsPerPage, setRowsPerPage]               = useState(20);
	const [filterField, setFilterField]               = useState('biz_no');
	const [filterType, setFilterType]                 = useState('like');
	const [filterValue, setFilterValue]               = useState('');
	const [totalCount, setTotalCount]                 = useState(0);

	// remote 방식에 따른 페이징 처리
	const apiUrl   = API_BASE_URL;
	const userInfo = useSelector(state => state.login.userInfo);
	const token    = userInfo?.access_token;

	// ①. 강제로 쿠키 삭제를 통한 로그인 정보가 없는 경우
    const persistInfo = sessionStorage.getItem('persist:login');

    if (!persistInfo) {
        window.location.href = '/login';
    };

	// ②. 토큰 만료 체크 루틴
	if (token) {
		const decodedToken            = jwtDecode(token);
		const expirationTimeInSeconds = decodedToken.exp;
		const currentTimeInSeconds    = Math.floor(Date.now() / 1000);

		if (expirationTimeInSeconds < currentTimeInSeconds) {
			// 토큰이 만료되었을 경우 로그인 페이지로 리디렉션
			dispatch(clearUserState());
			dispatch(clearLangCodeState());
			localStorage.removeItem('langCodeInfo');
			window.location.href = '/login';
		};
	};

	// Tabulator columns 목록
	const initializeColumns = (t) => {
		return [
			{ formatter:"rowSelection", field: "selected", hozAlign:"center", headerSort:false, width:30 },
			{ title: t('LOAN_APPLICATION.가입일자', { defaultValue: '가입일자' }), field: 'biz_reg_date', headerHozAlign: 'center', hozAlign: 'center', width:180, 
				formatter:function(cell, formatterParams, onRendered){
					var value = cell.getValue();
						value = moment(value).format("DD/MM/YYYY");

					return value;
				} 
			},
			{ title: '<i class="fa-solid fa-link"></i> 사업자번호', field: 'biz_no', headerHozAlign: 'center', hozAlign: 'center', width:200, 
				formatter: function(cell, formatterParams, onRendered){
					cell.getElement().style.fontWeight = 'bold';
					cell.getElement().style.color = 'green';

					return cell.getValue();
				},
				cellClick:function(e, cell){
					const rowData = cell.getRow().getData();
					handleBizCellClick(rowData);
				},
			},
			{ title: t('LOAN_APPLICATION.사업자명', { defaultValue: '사업자명' }), field: 'biz_name', headerHozAlign: 'center', hozAlign: 'center', width:300 },
			{ title: `<i class="fa-solid fa-link"></i> ${t('CODE_INFORMATION.지점수', { defaultValue: '지점수' })}`, field: 'branch_cnt', headerHozAlign: 'center', hozAlign: 'center', width:120,
				formatter: function(cell, formatterParams, onRendered){
					cell.getElement().style.fontWeight = 'bold';
					cell.getElement().style.color = 'green';

					return cell.getValue();
				},
				cellClick:function(e, cell){
					const rowData = cell.getRow().getData();
					if (rowData.branch_cnt > 0) {
						handleBranchCellClick(rowData);
					}
				},
			},
			// { title: t('CODE_INFORMATION.지점명', { defaultValue: '지점명' }), field: 'branch_name', headerHozAlign: 'center', hozAlign: 'center', width:120 },
			{ title: t('REPORT.대표자ID', { defaultValue: '대표자ID' }), field: 'biz_repr_id', headerHozAlign: 'center', hozAlign: 'center', width:200 },
			{ title: t('COLLECTION_MANAGEMENT.상태', { defaultValue: '상태' }), field: 'biz_status', headerHozAlign: 'center', hozAlign: 'center', width: 100 },
			{ title: t('COLLECTION_MANAGEMENT.적요', { defaultValue: '적요' }), field: 'biz_remark', headerHozAlign: 'center', hozAlign: 'center', width:150 },
		];
	};

	const [columns, setColumns]                       = useState(initializeColumns(t));

	// i18 선택된 언어에 따라 필요한 필드 값 번역
	const translateItem = useCallback((item, language) => ({
		...item,
		branch_type   : item.branch_type && t(`OFFICE_TYPE.${item.branch_type}`, { defaultValue : item.branch_type, lng: language }),
		biz_status    : item.biz_status && t(`USER_STATUS.${item.biz_status}`, { defaultValue : item.biz_status, lng: language }),
		branch_status : item.branch_status && t(`USER_STATUS.${item.branch_status}`, { defaultValue : item.branch_status, lng: language  }),
	}), [t]);

	// Tabulator Options
	// options 객체를 상태로 관리
	const [options, setOptions] = useState({
		ajaxURL     : `${apiUrl}/contract/contract_001`,
		ajaxConfig  : {
			method  : 'GET',
			headers : {
				'Content-Type' : 'application/json',
				'Authorization': `Bearer ${token}`,
			},
		},
		ajaxParams : {
			startDate      : moment(startDate).format('YYYY-MM-DD'),
			endDate        : moment(endDate).format('YYYY-MM-DD'),
			bizActiveField : bizActiveField,
			bizActiveValue : bizActiveValue,
			page           : tableRef.current && tableRef.current.table ? tableRef.current.getPage() : 1,
			size           : tableRef.current && tableRef.current.table ? tableRef.current.getPageSize() : 20, // 페이지 크기 정보
		},
		layout                 : "fitColumns",
		pagination             : true,
		paginationMode         : "remote",
		paginationSize         : rowsPerPage,
		paginationSizeSelector : [20, 40, 60, 80, 100],
		selectable             : 1,
		ajaxResponse : function (url, params, response) {

			// 값이 변경되면 직접 타이핑 하는 구간은 초기화
			setBizActiveValue('');

			const total_count    = response.total_count || 0;
			const translatedData = response.data.map(item => translateItem(item, i18n.language));
			const responseData   = {
				last_page : response.last_page,
				data      : translatedData,
			};

			setTotalCount(total_count);

			return responseData;
		}
	});

	// 로딩 중일 때 이전의 pagination 관련 정보를 유지하도록 설정
	const loadingOptions = {
		pagination             : true,
		paginationMode         : "remote",
		paginationSize         : rowsPerPage, // 이전 페이지 사이즈를 유지
		paginationSizeSelector : [20, 40, 60, 80, 100],
	};

	// 사업자번호 or 사업자명
	const handleBizLabelClick = (field) => {
		setBizActiveValue('');
		setBizActiveField(field);
	};

	// 오늘 버튼 액션
	const handleChangeTodayDate = () => {
		setStartDate(new Date());
		setEndDate(new Date());
	};

	// 어제 버튼 액션
	const handleChangeYesterDayDate = () => {
		setEndDate(new Date());
		
		const today     = new Date();
		const yesterday = new Date(today.setDate(today.getDate() - 1));
		setStartDate(yesterday);
	};

	// 이번달 버튼 액션
	const handleChangeMonthDate = () => {
		setEndDate(new Date());

		const today       = new Date();
		const oneMonthAgo = today.setMonth(today.getMonth() - 1);
		setStartDate(oneMonthAgo);
	};

	// 전체 버튼 액션
	const handleChangeIgnoreDate = () => {
		setStartDate('');
		setEndDate('');
	};

	// 시작일 값 변경
	const handleChangeStartDate = (date) => {
		setStartDate(date);
	};

	// 종료일 값 변경
	const handleChangeEndDate = (date) => {
		setEndDate(date);
	};

	// 사업자등록 모달 열기
	const handleOpenBizModal = () => {
		setDetailData([]);
		setShowBizModal(true);
	};

	// 지점등록 모달 열기
	const handleOpenBranchModal = () => {
		setDetailData([]);
		setModify(false);
		const selectedData = tableRef.current?.getSelectedData();

		if (selectedData.length === 1) {
			setDetailData(selectedData[0]);
			setShowBranchEditModal(true);
		} else {
			alert('하나의 사업자번호를 선택 하여야 합니다.');
			return;
		}
	};

	// 수수료등록 모달 열기
	const handleOpenFeeModal = () => {
		setDetailData([]);
		const selectedData = tableRef.current?.getSelectedData();

		if (selectedData.length === 1) {
			setDetailData(selectedData[0]);
			setShowFeeModal(true);
		} else {
			alert('하나의 사업자번호를 선택 하여야 합니다.');
			return;
		}
	};

	// 사업자등록 모달 열기 (수정)
	const handleBizCellClick = (rowData) => {
		setDetailData(rowData);
		setShowBizModal(true);
	};

	// 지점등록 모달 열기 (수정)
	const handleBranchCellClick = (rowData) => {
		setDetailData(rowData);
		setModify(true);
		setShowBranchModal(true);
	};

	// 조회 버튼 작동 시 실행되는 함수
	const handleQuery = useCallback(
		async () => {
			setLoading(true);

			// ReactTabulator의 내부 로직을 사용하여 API 호출
			try {
				// ReactTabulator에서 사용하는 API URL과 Params를 사용하여 호출
				const url  = new URL(options.ajaxURL);
				url.search = new URLSearchParams({
					...options.ajaxParams,
					bizActiveField : bizActiveField,
					bizActiveValue : bizActiveValue,
					page           : tableRef.current?.getPage(),     // 현재 페이지 정보
					size           : tableRef.current?.getPageSize(), // 페이지 크기 정보
				});

				const data = await fetch(url, {
					method  : options.ajaxConfig.method,
					headers : options.ajaxConfig.headers,
				});

				const responseData = await data.json();

				// 전달 받은 총 데이터 건수
				const total_count = responseData.total_count || 0;
		
				// 번역이 필요한 부분
				const translatedData = responseData.data.map(item => translateItem(item, i18n.language));
		
				// 페이징 및 데이터 리턴
				const updatedData = {
					last_page : responseData.last_page, // 전체 페이지 수 계산
					data      : translatedData, // 실제 테이블 데이터
				};

				// 총 건수를 화면에 표현하기 위해서
				setTotalCount(total_count);

				// 테이블 데이터를 초기화하고 새로운 데이터로 업데이트
				if (tableRef.current) {
					// 데이터 로드가 완료된 후에만 테이블 데이터를 변경하도록 함
					if (!loading) {
						tableRef.current.clearData();
						tableRef.current.setData(updatedData.data || []);
					}
				}

				// 데이터 로드 완료 후 로딩 상태를 false로 변경하여 로딩 중임을 표시하지 않음
				setLoading(false);

			} catch (error) {
				console.error('Error in Search Contract_001 Data : ', error);
				setLoading(false);
			} 
		}, [options, bizActiveField, bizActiveValue, i18n.language, translateItem, tableRef, loading]
	);

	// 조회 조건이 바뀜에 따라 ajaxParams 동적 변경
	useEffect(() => {
		setOptions((prevOptions) => {
			const updatedOptions = {
				...prevOptions,
				ajaxParams: {
					...prevOptions.ajaxParams,
					startDate : startDate ? moment(startDate).format('YYYY-MM-DD') : '',
					endDate   : endDate ? moment(endDate).format('YYYY-MM-DD') : '',
				},
			};
			return updatedOptions;
		});
	}, [startDate, endDate]);

	// 조회버튼 클릭 시
	const handleSearch = () => {
		// 로딩 중인 경우 조회 버튼 동작을 막음
		if (!loading) {
			bizActiveFieldRef.current = bizActiveField;
			bizActiveValueRef.current = bizActiveValue;

			handleQuery();
		}
	};

	// i18 언어에 맞춰서 자동 갱신 (컬럼명, Grid 내용)
	useEffect(() => {
		setColumns(initializeColumns(t));
	}, [i18n.language, t]);

	// Filter 값에 따른 Tabulator 변화가 일어나는 함수
	const updateFilter = () => {
		if (tableRef.current) {
			tableRef.current.setFilter(filterField, filterType, filterValue);
		}
	};

	// value값을 입력할 때마다 해당되는 Tabulator가 화면에 보이도록 함수호출하는 부분
	const handleKeyUp = () => {
		updateFilter();
	};

	// UI
	return (
		<Card style={{ height: '100%' }}>
			<Card.Header><strong>{t('LOAN_APPLICATION.계약관리', { defaultValue: '계약관리' })}</strong></Card.Header>
			<Card.Body className='responsive-card-body m-1'>
				<div className="panel-body">
					<div className="row ms-0">
						<div className="form-group col-lg-2 col-md-12 col-xs-12 mb-2 ps-0 rounded-2">
							<label className={`form-label select-label me-2 ${bizActiveField === 'biz_no' ? 'active' : ''}`} onClick={() => handleBizLabelClick('biz_no')} htmlFor="biz_no">
								{t('LOAN_APPLICATION.사업자번호', { defaultValue: '사업자번호' })}
							</label>

							<label className={`form-label select-label ${bizActiveField === 'biz_name' ? 'active' : ''}`} onClick={() => handleBizLabelClick('biz_name')} htmlFor="biz_name">
								{t('LOAN_APPLICATION.사업자명', { defaultValue: '사업자명' })}
							</label>

							<input className='form-control' value={bizActiveValue} onChange={(e) => setBizActiveValue(e.target.value)}></input>
						</div>

						<div className="form-group col-lg-6 col-md-12 col-xs-12 mb-2 ps-0 pe-5 rounded-2">
							<label className="form-label" htmlFor="date_id">
								<i className="bi bi-calendar3"></i> {t('LOAN_APPLICATION.가입일자', { defaultValue: '가입일자' })}
							</label>

							<div className="input-group input-daterange">
								<div className='col'>
									<DatePicker
										className='form-control'
										selected={startDate}
										onChange={handleChangeStartDate}
										dateFormat="dd/MM/yyyy"
									/>
								</div>

								<div className="col-auto">
									<span className="input-group-text input-group-addon"> ~ </span>
								</div>

								<div className='col'>
									<DatePicker
										className='form-control'
										selected={endDate}
										onChange={handleChangeEndDate}
										dateFormat="dd/MM/yyyy"
									/>
								</div>
								<div className="mb-0">
									<span>
										<button type="button" className="btn btn-success btn-xs me-1 w-100px m-2" onClick={handleChangeTodayDate}>{t('LOAN_APPLICATION.오늘', { defaultValue: '오늘' })}</button>
										<button type="button" className="btn btn-success btn-xs me-1 w-100px" onClick={handleChangeYesterDayDate}>{t('LOAN_APPLICATION.어제', { defaultValue: '어제' })}</button>
										<button type="button" className="btn btn-success btn-xs me-1 w-100px" onClick={handleChangeMonthDate}>{t('LOAN_APPLICATION.이번달', { defaultValue: '이번달' })}</button>
										<button type="button" className="btn btn-success btn-xs w-100px" onClick={handleChangeIgnoreDate}>{t('LOAN_APPLICATION.전체', { defaultValue: '전체' })}</button>
									</span>
								</div>
							</div>
						</div>

						<div className="form-group col-lg-3 col-md-12 col-xs-12"></div>

						<div className="form-group col-lg-1 col-md-12 col-xs-12 ps-0 rounded-2">
							<div className="mb-3"></div>
							<button type="button" className="btn btn-success float-end float-bottom w-100 h-30.5px align-baseline mt-8px" onClick={handleSearch}>{t('LOAN_APPLICATION.조회', { defaultValue: '조회' })}</button>
						</div>

						<div className="mt-3 mb-3 ps-0">
							<i id="filter-icon" className="cursor-pointer fa fa-plus-square" data-bs-toggle="collapse" href="#collapseExample" aria-expanded="true" aria-controls="collapseExample"></i>
							<span> Filter</span>
						</div>

						<div className="collapse show" id="collapseExample">
							<div className="row">
								<div className="form-group col-lg-2 col-md-12 col-xs-12 ps-0 rounded-2 mb-1">
									<select 
										id="filter-field" 
										className="form-select col-sm-3" 
										value={filterField}
										onChange={(e) => setFilterField(e.target.value)}
									>
										<option value="biz_no">사업자번호</option>
										<option value="biz_name">{t('LOAN_APPLICATION.사업자명', { defaultValue: '사업자명' })}</option>
										<option value="biz_repr_id">{t('LOAN_APPLICATION.대표자ID', { defaultValue: '대표자ID' })}</option>
									</select>
								</div>
					
								<div className="form-group col-lg-2 col-md-12 col-xs-12 ps-0 rounded-2 mb-1">
									<select 
										id="filter-type" 
										className="form-select col-sm-3"
										value={filterType}
										onChange={(e) => setFilterType(e.target.value)}
									>
										<option value="like">LIKE</option>
										<option value="=">=</option>
										<option value="<">&lt;</option>
										<option value="<=">&le;</option>
										<option value=">">&gt;</option>
										<option value=">=">&ge;</option>
										<option value="!=">!=</option>
									</select>
								</div>
					
								<div className="form-group col-lg-8 col-md-12 col-xs-12 ps-0 rounded-2 mb-3">
									<input 
										id="filter-value" 
										type="text" 
										className="form-control col-sm-6" 
										name="filter-value" 
										placeholder={t('COMMON.검색할 내용을 입력하세요', { defaultValue: '검색할 내용을 입력하세요' })}
										value={filterValue}
										onChange={(e) => setFilterValue(e.target.value)}
										onKeyUp={handleKeyUp}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* 로딩 관련 gif */}
				{loading && 
					<div
						style={{
							position: 'fixed',
							top: '50%',
							left: '55%',
							transform: 'translate(-50%, -50%)',
							zIndex: 9999,
						}}
					>
						<img src={loader} alt="Loading..." />
					</div>
				}

				{/* Tabulator */}
				<div id="tabulator-total-info" className='d-flex justify-content-end fw-bold'>{t(`LOAN_APPLICATION.총 {{count}}건`, { count: totalCount, defaultValue: '총 {{count}}건' })}</div>
				<ReactTabulator
					key         = {i18n.language + JSON.stringify(columns) + JSON.stringify(options)} // 언어 변경 및 옵션 변경에 따라 컴포넌트 리렌더링
					onRef       = {(r) => (tableRef.current = r.current)}
					columns     = {columns}
					options     = {loading ? loadingOptions : options} // 로딩 중일 때는 이전의 pagination 관련 정보를 유지하고 데이터를 변경하지 않도록 함
					placeholder = "No Data Found"
					className   = "responsive-tabulator"
				/>
			</Card.Body>

			<Card.Footer className='card-footer d-flex justify-content-start mt-auto'>
				<button type="button" className="btn btn-outline-info mb-1 me-1 w-auto" onClick={handleOpenBizModal}>{t('LOAN_APPLICATION.사업자등록', { defaultValue: '사업자등록' })}</button>
				<button type="button" className="btn btn-outline-success me-1 mb-1 w-auto" onClick={handleOpenBranchModal}>{t('LOAN_DISBURSEMENT.지점등록', { defaultValue: '지점등록' })}</button>
				<button type="button" className="btn btn-outline-success me-1 mb-1 w-auto" onClick={handleOpenFeeModal}>{t('LOAN_DISBURSEMENT.수수료등록', { defaultValue: '수수료등록' })}</button>
			</Card.Footer>

			{/* 사업자등록 모달 */}
			<Contract001_01 showModal={showBizModal} setShowBizModal={setShowBizModal} handleSearch={handleSearch} DetailData={detailData} />

			{/* 지점등록 모달 */}
			<Contract001_02 showModal={showBranchEditModal} setShowBranchEditModal={setShowBranchEditModal} handleSearch={handleSearch} DetailData={detailData} modify={modify} setModify={setModify} />

			{/* 지점수정 모달 */}
			<Contract001_02_01 showModal={showBranchModal} setShowBranchModal={setShowBranchModal} handleSearch={handleSearch} DetailData={detailData} modify={modify} setModify={setModify} />

			{/* 수수료등록 모달 */}
			<Contract001_03 showModal={showFeeModal} setShowFeeModal={setShowFeeModal} handleSearch={handleSearch} DetailData={detailData} />
		</Card>
	);
};

export default Contract001;