import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading      : false,
    error        : undefined,
    langCodeInfo : undefined,
};

const langCodeSlice = createSlice({
    name: 'langCode',
    initialState,
    reducers: {
        setLangCodeLoading: (state, action) => {
            state.loading = action.payload;
        },
        setLangCodeError: (state, action) => {
            state.error = action.payload;
        },
        setLangCodeInfo: (state, action) => {
            const { payload } = action;
            state.langCodeInfo = payload;
            localStorage.setItem('langCodeInfo', JSON.stringify(action.payload));
        },
        clearLangCodeState: (state) => {
            localStorage.removeItem('langCodeInfo');
            state.loading = false;
            state.error = undefined;
            state.langCodeInfo = undefined;
        },
    },
});

export const { setLangCodeLoading, setLangCodeError, setLangCodeInfo, clearLangCodeState } = langCodeSlice.actions;

export default langCodeSlice.reducer;