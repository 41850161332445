import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading    : false,
    error      : undefined,
    userInfo   : undefined,
    rememberMe : false,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        setUserInfo: (state, action) => {
            const { payload } = action;

            // state.userInfo가 undefined인 경우 초기 상태로 설정
            if (!state.userInfo) {
                state.userInfo = initialState.userInfo;
            }
            
            // 직접 불변성을 유지하면서 state를 업데이트합니다.
            state.userInfo = {
                ...state.userInfo,
                user_seq      : payload.user_seq,
                user_id       : payload.user_id,
                name          : payload.name,
                authority     : payload.authority,
                access_token  : payload.access_token,
                refresh_token : payload.refresh_token,
                lang_code     : payload.lang_code !== undefined ? payload.lang_code : state.userInfo.lang_code,
            };
        },
        clearUserState: (state) => {
            state.loading  = initialState.loading;
            state.userInfo = initialState.userInfo;
            state.error    = initialState.error;
        },
        setRememberMe: (state, action) => {
            state.rememberMe = action.payload;
        },
    },
});

export const { setLoading, setError, setUserInfo, clearUserState, setRememberMe } = userSlice.actions;

export default userSlice.reducer;