import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal} from 'react-bootstrap';
import moment from 'moment';
import CustomToast from '../../components/toast/toast.jsx';
import API from '../../api/api.jsx';

// 수수료등록 모달
const Contract001_03 = ({ showModal, setShowFeeModal, handleSearch, DetailData }) => {
    const { t }                           = useTranslation();

     // 토스트
	const [toast, setToast]               = useState(false);
	const [text, setText]                 = useState('');

    // 수정여부 체크
    const isEditMode         = !!DetailData.fee_type;

    const initFee_type       = isEditMode ? DetailData.fee_type : 'T01';    // 수수료 구분
    const initMembership_fee = isEditMode ? DetailData.membership_fee : 0; // 가입비 
    const initMonthly_fee    = isEditMode ? DetailData.monthly_fee : 0;    // 기본월수수료
    const initBranch_fee     = isEditMode ? DetailData.branch_fee : 0;     // 지점별 수수료
    const initZalo_otp_fee   = isEditMode ? DetailData.zalo_otp_fee : 0;   // ZALO OTP 수수료
    const initZalo_etc_fee   = isEditMode ? DetailData.zalo_etc_fee : 0;   // ZALO OTP 외 수수료

    const [formData, setFormData] = useState({
        title            : '',
        biz_code         : '',
        biz_no           : '',
        biz_name         : '',
        biz_reg_date     : '',
        biz_reg_location : '',
        biz_repr_id      : '',
        biz_status       : '',
        biz_remark       : '',
        fee_type         : '',
        membership_fee   : 0,
        monthly_fee      : 0,
        branch_fee       : 0,
        zalo_otp_fee     : 0,
        zalo_etc_fee     : 0,
    });

    // 렌더링
    useEffect(() => {
        // 모달이 활성화 된 경우
        if (showModal) {
            setFormData({
                title            : 'fee',
                biz_code         : DetailData.biz_code,
                biz_no           : DetailData.biz_no,
                biz_name         : DetailData.biz_name,
                biz_reg_date     : DetailData.biz_reg_date,
                biz_reg_location : DetailData.biz_reg_location,
                biz_repr_id      : DetailData.biz_repr_id,
                biz_status       : DetailData.biz_status,
                biz_remark       : DetailData.biz_remark,
                fee_type         : initFee_type,
                membership_fee   : initMembership_fee,
                monthly_fee      : initMonthly_fee,
                branch_fee       : initBranch_fee,
                zalo_otp_fee     : initZalo_otp_fee,
                zalo_etc_fee     : initZalo_etc_fee,
            });
        }
    }, [showModal, isEditMode, DetailData, initFee_type, initMembership_fee, initMonthly_fee, initBranch_fee, initZalo_otp_fee, initZalo_etc_fee]);

    const handleChange = (e, id) => {
        if (id === 'T01' || id === 'T02') {
            setFormData((prevData) => ({ ...prevData, fee_type: id === 'T01' ? 'T01' : 'T02' }));
        } else {
            let { value } = e.target;

            // 입력값이 빈 문자열인 경우 0으로 대체
            if (value === '') {
                value = 0;
            }
            setFormData((prevData) => ({ ...prevData, [id]: value }));
        }
    };

    // 저장 함수
    const handleSave = async (e) => {
        e.preventDefault();

        // 필수 항목들의 키 배열
        const requiredFields = [
            'membership_fee',
        ];

        // 필수 항목 중 하나라도 값이 비어있으면 알림 띄우기
        for (const field of requiredFields) {
            if (!formData[field]) {
                alert('필수 값이 입력되지 않았습니다.');
                return;
            }
        };

        try {
            const confirmationMessage = isEditMode ? '수수료정보를 수정하시겠습니까?' : '수수료정보를 등록하시겠습니까?';
            const confirmed           = window.confirm(confirmationMessage);

            if (confirmed) {
                const savedData = await API.saveContractInfo({ formData });
    
                // 생성
                if (savedData.code === 201) {
                    const message = savedData.result.content.message;
    
                    setText(message);
    
                    setToast(true);
    
                    handleClose();
    
                    return;
                };
    
                // 수정
                if (savedData.code === 200) {
                    const message = savedData.result.content.message;
    
                    setText(message);
    
                    setToast(true);
    
                    handleClose();
    
                    return;
                }
            }

        } catch (error) {
            const message = error.response.data.result.content;
            alert(message);
        };
    };

    // 닫기 함수
    const handleClose = () => {
        setFormData({
            title            : '',
            biz_code         : '',
            biz_no           : '',
            biz_name         : '',
            biz_reg_date     : '',
            biz_reg_location : '',
            biz_repr_id      : '',
            biz_status       : '',
            biz_remark       : '',
            fee_type         : '',
            membership_fee   : 0,
            monthly_fee      : 0,
            branch_fee       : 0,
            zalo_otp_fee     : 0,
            zalo_etc_fee     : 0,
        });

        setShowFeeModal(false);

        handleSearch();
    };

    function numberWithCommas(x) {
        if (!x && x !== 0) return 0; // 수정된 부분
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    return (
        <>
            <CustomToast show={toast} setToast={setToast} text={text} />
            <Modal show={showModal} onHide={handleClose} centered dialogClassName="modal-lg" backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{t('STAFF_MANAGEMENT.사업자정보', { defaultValue: '사업자정보' })}</Modal.Title>
                </Modal.Header>

                <Modal.Body className="text-start">
                    <div className='row ms-0'>
                        <div className='col-md-2 bg-light d-flex align-items-center pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.사업자 번호`, { defaultValue: '사업자 번호' })}</div>
                        <div className='col-md-4 pt-2 pb-2'>
                            <input className="form-control border-0" type="text" value={DetailData.biz_no || ''} readOnly={true} />
                        </div>

                        <div className='col-md-2 bg-light d-flex align-items-center pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.사업자명`, { defaultValue: '사업자명' })}</div>
                        <div className='col-md-4 pt-2 pb-2 d-flex align-items-center'>
                            <input className="form-control border-0" type="text" value={DetailData.biz_name || ''} readOnly={true} />
                        </div>
                    </div>

                    <div className='row ms-0'>
                        <div className='col-md-2 bg-light d-flex align-items-center pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.사업자등록일자`, { defaultValue: '사업자등록일자' })}</div>
                        <div className='col-md-4 pt-2 pb-2'>
                            <input className="form-control border-0" type="text" value={moment(DetailData.biz_reg_date).format('DD/MM/YYYY') || ''} readOnly={true} />
                        </div>

                        <div className='col-md-2 bg-light d-flex align-items-center pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.사업자등록장소`, { defaultValue: '사업자등록장소' })}</div>
                        <div className='col-md-4 pt-2 pb-2 d-flex align-items-center'>
                            <input className="form-control border-0" type="text" value={DetailData.biz_reg_location || ''} readOnly={true} />
                        </div>
                    </div>

                    <div className='row ms-0'>
                        <div className='col-md-2 bg-light d-flex align-items-center pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.대표자ID`, { defaultValue: '대표자ID' })}</div>
                        <div className='col-md-4 pt-2 pb-2'>
                            <input className="form-control border-0" type="text" value={DetailData.biz_repr_id || ''} readOnly={true} />
                        </div>

                        <div className='col-md-2 bg-light d-flex align-items-center pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.상태`, { defaultValue: '상태' })}</div>
                        <div className='col-md-4 pt-2 pb-2 d-flex align-items-center'>
                            <input className="form-control border-0" type="text" value={DetailData.biz_status || ''} readOnly={true} />
                        </div>
                    </div>

                    <div className='row ms-0'>
                        <div className='col-md-2 bg-light d-flex align-items-center pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.적요`, { defaultValue: '적요' })}</div>
                        <div className='col-md-10 pt-2 pb-2'>
                            <input className="form-control border-0" type="text" value={DetailData.biz_remark || ''} readOnly={true} />
                        </div>
                    </div>

                    <div className='row ms-0'>
                        <div className='col-md-2 bg-white d-flex align-items-center fw-bold pt-3 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.수수료 정보`, { defaultValue: '수수료 정보' })}</div>
                    </div>

                    <div className='row ms-0'>
                        <div className='col-md-2 bg-light d-flex align-items-center pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.수수료 구분`, { defaultValue: '수수료 구분' })}</div>
                        <div className='col-md-10 pt-2 pb-2 d-flex justify-content-around'>
                            <label htmlFor="T01">
                                <input 
                                    id='T01' 
                                    type="radio"
                                    checked={formData['fee_type'] === 'T01'}
                                    onChange={(e) => handleChange(e, 'T01')}
                                /> A type
                            </label>

                            <label htmlFor="T02">
                                <input
                                    id='T02' 
                                    type="radio" 
                                    checked={formData['fee_type'] === 'T02'}
                                    onChange={(e) => handleChange(e, 'T02')}
                                /> B type
                            </label>
                        </div>
                    </div>

                    <div className='row ms-0'>
                        <div className='col-md-2 bg-light d-flex align-items-center pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.가입비`, { defaultValue: '가입비' })} <span className="text-danger">*</span></div>
                        <div className='col-md-4 pt-2 pb-2'>
                            <input type='text' className='form-control text-end' value={numberWithCommas(formData.membership_fee) || 0}
                                onInput={(e) => {
                                    e.target.value = e.target.value.replace(/[^0-9]/g, "").replace(/(\..*)\./g, "$1");
                                    handleChange(e, 'membership_fee');
                                }} 
                                onChange={(e) => handleChange(e, 'membership_fee')}>
                            </input>
                        </div>

                        <div className='col-md-2 bg-light d-flex align-items-center pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.기본월수수료`, { defaultValue: '기본월수수료' })}</div>
                        <div className='col-md-4 pt-2 pb-2 d-flex align-items-center'>
                            <input type='text' className='form-control text-end' value={numberWithCommas(formData.monthly_fee) || 0} 
                                onInput={(e) => {
                                    e.target.value = e.target.value.replace(/[^0-9]/g, "").replace(/(\..*)\./g, "$1");
                                    handleChange(e, 'monthly_fee');
                                }} 
                                onChange={(e) => handleChange(e, 'monthly_fee')}>
                            </input>
                        </div>
                    </div>

                    <div className='row ms-0'>
                        <div className='col-md-2 bg-light d-flex align-items-center pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.지점별 수수료`, { defaultValue: '지점별 수수료' })}</div>
                        <div className='col-md-4 pt-2 pb-2'>
                            <input type='text' className='form-control text-end' value={numberWithCommas(formData.branch_fee) || 0} 
                                onInput={(e) => {
                                    e.target.value = e.target.value.replace(/[^0-9]/g, "").replace(/(\..*)\./g, "$1");
                                    handleChange(e, 'branch_fee');
                                }} 
                                onChange={(e) => handleChange(e, 'branch_fee')}>
                            </input>
                        </div>

                        <div className='col-md-2 bg-light d-flex align-items-center pt-2 pb-2'></div>
                        <div className='col-md-4 pt-2 pb-2 d-flex align-items-center'></div>
                    </div>

                    <div className='row ms-0'>
                        <div className='col-md-2 bg-light d-flex align-items-center pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.ZALO OTP 수수료`, { defaultValue: 'ZALO OTP 수수료' })}</div>
                        <div className='col-md-4 pt-2 pb-2'>
                            <input type='text' className='form-control text-end' value={numberWithCommas(formData.zalo_otp_fee) || 0} 
                                onInput={(e) => {
                                    e.target.value = e.target.value.replace(/[^0-9]/g, "").replace(/(\..*)\./g, "$1");
                                    handleChange(e, 'zalo_otp_fee');
                                }} 
                                onChange={(e) => handleChange(e, 'zalo_otp_fee')}>
                            </input>
                        </div>

                        <div className='col-md-2 d-flex align-items-center pt-2 pb-2' style={{ backgroundColor : '#CFD7E7', whiteSpace: 'pre-line' }}>{t(`LOAN_RECEIVABLE_INQUIRY.ZALO OTP외\n수수료`, { defaultValue: 'ZALO OTP외\n수수료' })}</div>
                        <div className='col-md-4 pt-2 pb-2 d-flex align-items-center' style={{ backgroundColor : '#CFD7E7' }}>
                            <input type='text' className='form-control text-end' value={numberWithCommas(formData.zalo_etc_fee) || 0} 
                                onInput={(e) => {
                                    e.target.value = e.target.value.replace(/[^0-9]/g, "").replace(/(\..*)\./g, "$1");
                                    handleChange(e, 'zalo_etc_fee');
                                }} 
                                onChange={(e) => handleChange(e, 'zalo_etc_fee')}>
                            </input>
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer className="d-flex justify-content-around">
                    <button className="btn btn-outline-gray me-1 mb-1 w-100px" onClick={handleClose}>
                        {t('COMMON.취소', { defaultValue: '취소' })}
                    </button>

                    <button className="btn btn-outline-primary me-1 mb-1 w-100px" onClick={handleSave}>
                        {isEditMode 
                            ? t('COMMON.수정', { defaultValue: '수정' })
                            : t('COMMON.저장', { defaultValue: '저장' })
                        }
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Contract001_03;