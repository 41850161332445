// API URL
export const API_BASE_URL = (() => {
    if (process.env.REACT_APP_ENV === 'dev') {
        return process.env.REACT_APP_DEV_API_URL;
    } else if (process.env.REACT_APP_ENV === 'stage') {
        return process.env.REACT_APP_STAGE_API_URL;
    } else if (process.env.REACT_APP_ENV === 'prod') {
        return process.env.REACT_APP_PROD_API_URL;
    } else {
        console.error('환경을 확인할 수 없습니다.');
        return '';
    }
})();

// MEDIA URL
export const MEDIA_BASE_URL = (() => {
    if (process.env.REACT_APP_ENV === 'dev') {
        return process.env.REACT_APP_DEV_MEDIA_URL;
    } else if (process.env.REACT_APP_ENV === 'stage') {
        return process.env.REACT_APP_STAGE_MEDIA_URL;
    } else if (process.env.REACT_APP_ENV === 'prod') {
        return process.env.REACT_APP_PROD_MEDIA_URL;
    } else {
        console.error('환경을 확인할 수 없습니다.');
        return '';
    }
})();