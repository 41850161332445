import React from 'react';
import '../../scss/footer/footer.scss'

class Footer extends React.Component {
	
	render() {
		return (
			<footer className="custom-footer">
                <p>Copyright &copy; DIDO. All Rights Reserved.</p>
            </footer>
		)
	}
}

export default Footer;
