import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactTabulator } from 'react-tabulator';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { jwtDecode } from 'jwt-decode';
import moment from 'moment';
import { API_BASE_URL } from '../../config/app-axios.jsx';
import { clearUserState } from '../../reducers/userReducers.jsx';
import { clearLangCodeState } from '../../reducers/langCodeReducers.jsx';
import Contract001_02 from './contract_001_02.jsx';

// 지점등록 모달
const Contract001_02_01 = ({ showModal, setShowBranchModal, handleSearch, DetailData, modify, setModify }) => {
    const { t, i18n }                     = useTranslation();
    const dispatch                        = useDispatch();
    const tableRef                        = useRef(null);

    // 지점통계 조회 관련
    const [rowsPerPage, setRowsPerPage]   = useState(20);
    const [totalCount, setTotalCount]     = useState(0);
    const [columns, setColumns]           = useState([]);
    const [options, setOptions]           = useState([]);

    // 모달폼오픈
    const [modifyData, setModifyData]                   = useState([]);
    const [showBranchEditModal, setShowBranchEditModal] = useState(false);

    // Tabulator columns 목록
	const initializeColumns = (t, data) => {
		return [
			{ title: 'NO', formatter: 'rownum', headerHozAlign: 'center', hozAlign:"center", headerSort:false, width:30 },
			{ title: '계약일자', field: 'contract_date', headerHozAlign: 'center', hozAlign: 'center', width:120, 
				formatter:function(cell, formatterParams, onRendered){
					var value = cell.getValue();
						value = moment(value).format("DD/MM/YYYY");

					return value;
				} 
			},
			{ title: '지점코드', field: 'branch_code', headerHozAlign: 'center', hozAlign: 'center', width:100 },
			{ title: `<i class="fa-solid fa-link"></i> 지점명`, field: 'branch_name', headerHozAlign: 'center', hozAlign: 'center', vertAlign: 'middle', width:200,
				formatter: function(cell, formatterParams, onRendered){
					cell.getElement().style.fontWeight = 'bold';
					cell.getElement().style.color = 'green';

					return cell.getValue();
				},
				cellClick:function(e, cell){
					const rowData = cell.getRow().getData();
					handleBranchCellClick(rowData, data);
				},
			},
			{ title: '지점 종류', field: 'branch_type', headerHozAlign: 'center', hozAlign: 'center', width:120 },
			{ title: '지점 상태', field: 'branch_status', headerHozAlign: 'center', hozAlign: 'center', width:120 },
			{ title: '지점 매니저 ID', field: 'manager_id', headerHozAlign: 'center', hozAlign: 'center', width:180 },
		];
	};
    

    // remote 방식에 따른 페이징 처리
	const apiUrl   = API_BASE_URL;
	const userInfo = useSelector(state => state.login.userInfo);
	const token    = userInfo?.access_token;

	// ①. 강제로 쿠키 삭제를 통한 로그인 정보가 없는 경우
    const persistInfo = sessionStorage.getItem('persist:login');

    if (!persistInfo) {
        window.location.href = '/login';
    };

	// ②. 토큰 만료 체크 루틴
	if (token) {
		const decodedToken            = jwtDecode(token);
		const expirationTimeInSeconds = decodedToken.exp;
		const currentTimeInSeconds    = Math.floor(Date.now() / 1000);

		if (expirationTimeInSeconds < currentTimeInSeconds) {
			// 토큰이 만료되었을 경우 로그인 페이지로 리디렉션
			dispatch(clearUserState());
			dispatch(clearLangCodeState());
			localStorage.removeItem('langCodeInfo');
			window.location.href = '/login';
		};
	};

     // i18 선택된 언어에 따라 필요한 필드 값 번역
	const translateItem = useCallback((item, language) => ({
		...item,
		branch_type   : item.branch_type && t(`OFFICE_TYPE.${item.branch_type}`, { defaultValue : item.branch_type, lng: language }),
		branch_status : item.branch_status && t(`USER_STATUS.${item.branch_status}`, { defaultValue : item.branch_status, lng: language }),
	}), [t]);

    // 렌더링
    useEffect(() => {
        // 모달이 활성화 된 경우
        if (showModal && DetailData) {
	        // options 객체를 상태로 관리
            setOptions({
                ajaxURL     : `${apiUrl}/contract/contract_001_01`,
                ajaxConfig  : {
                    method  : 'GET',
                    headers : {
                        'Content-Type' : 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                },
                ajaxParams : {
                    biz_code          : DetailData.biz_code,
                    page              : tableRef.current?.getPage(),
                    size              : tableRef.current?.getPageSize(),
                },
                layout                 : "fitColumns",
                pagination             : true,
                paginationMode         : "remote",
                paginationSize         : rowsPerPage,
                paginationSizeSelector : [20, 40, 60, 80, 100],
                selectable             : 1,
                ajaxResponse : function (url, params, response) {

                    const total_count    = response.total_count || 0;
                    const translatedData = response.data.map(item => translateItem(item, i18n.language));
                    const responseData   = {
                        last_page : response.last_page,
                        data      : translatedData,
                    };

                    setTotalCount(total_count);

                    return responseData;
                }
            });

            setColumns(initializeColumns(t, DetailData));
        }
    }, [showModal, DetailData]);

    // 지점등록 모달 열기 (수정)
	const handleBranchCellClick = (rowData, basicData) => {
        // rowData와 basicData를 합친 객체 생성
        const combinedData = {
            ...basicData,
            ...rowData,
        };

        setModifyData(combinedData);
        setModify(true);
        setShowBranchEditModal(true);  // 자식 모달 열기

        setShowBranchModal(false);
	};

    // 닫기 함수
    const handleClose = () => {
        setShowBranchModal(false);
        handleSearch();
    };

    return (
        <>
            <Modal show={showModal} onHide={handleClose} centered dialogClassName="modal-lg" backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{t('STAFF_MANAGEMENT.사업자정보', { defaultValue: '사업자정보' })}</Modal.Title>
                </Modal.Header>

                <Modal.Body className="text-start">
                    <div className='row ms-0'>
                        <div className='col-md-2 bg-light d-flex align-items-center pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.사업자 번호`, { defaultValue: '사업자 번호' })}</div>
                        <div className='col-md-4 pt-2 pb-2'>
                            <input className="form-control border-0" type="text" value={DetailData.biz_no || ''} readOnly={true} />
                        </div>

                        <div className='col-md-2 bg-light d-flex align-items-center pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.사업자명`, { defaultValue: '사업자명' })}</div>
                        <div className='col-md-4 pt-2 pb-2 d-flex align-items-center'>
                            <input className="form-control border-0" type="text" value={DetailData.biz_name || ''} readOnly={true} />
                        </div>
                    </div>

                    <div className='row ms-0'>
                        <div className='col-md-2 bg-light d-flex align-items-center pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.사업자등록일자`, { defaultValue: '사업자등록일자' })}</div>
                        <div className='col-md-4 pt-2 pb-2'>
                            <input className="form-control border-0" type="text" value={moment(DetailData.biz_reg_date).format('DD/MM/YYYY') || ''} readOnly={true} />
                        </div>

                        <div className='col-md-2 bg-light d-flex align-items-center pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.사업자등록장소`, { defaultValue: '사업자등록장소' })}</div>
                        <div className='col-md-4 pt-2 pb-2 d-flex align-items-center'>
                            <input className="form-control border-0" type="text" value={DetailData.biz_reg_location || ''} readOnly={true} />
                        </div>
                    </div>

                    <div className='row ms-0'>
                        <div className='col-md-2 bg-light d-flex align-items-center pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.대표자ID`, { defaultValue: '대표자ID' })}</div>
                        <div className='col-md-4 pt-2 pb-2'>
                            <input className="form-control border-0" type="text" value={DetailData.biz_repr_id || ''} readOnly={true} />
                        </div>

                        <div className='col-md-2 bg-light d-flex align-items-center pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.상태`, { defaultValue: '상태' })}</div>
                        <div className='col-md-4 pt-2 pb-2 d-flex align-items-center'>
                            <input className="form-control border-0" type="text" value={DetailData.biz_status || ''} readOnly={true} />
                        </div>
                    </div>

                    <div className='row ms-0'>
                        <div className='col-md-2 bg-light d-flex align-items-center pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.적요`, { defaultValue: '적요' })}</div>
                        <div className='col-md-10 pt-2 pb-2'>
                            <input className="form-control border-0" type="text" value={DetailData.biz_remark || ''} readOnly={true} />
                        </div>
                    </div>

                    <div className='row ms-0'>
                        <div className='col-md-2 bg-white d-flex align-items-center fw-bold pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.지점 현황`, { defaultValue: '지점 현황' })}</div>
                    </div>

                    {/* Tabulator */}
                    <div id="tabulator-total-info" className='d-flex justify-content-end fw-bold'>{t(`LOAN_APPLICATION.총 {{count}}건`, { count: totalCount, defaultValue: '총 {{count}}건' })}</div>
                    <ReactTabulator
                        key={i18n.language + JSON.stringify(columns) + JSON.stringify(options)} // 언어 변경 및 옵션 변경에 따라 컴포넌트 리렌더링
                        onRef={(r) => (tableRef.current = r.current)}
                        columns={columns}
                        options={options}
                        placeholder="No Data Found"
                        className="responsive-tabulator"
                    />
                </Modal.Body>

                <Modal.Footer className="d-flex justify-content-start">
                    <button className="btn btn-outline-gray me-1 mb-1 w-100px" onClick={handleClose}>
                        {t('COMMON.취소', { defaultValue: '취소' })}
                    </button>
                </Modal.Footer>

            </Modal>

            {/* 지점등록 모달 */}
            <Contract001_02 showModal={showBranchEditModal} setShowBranchEditModal={setShowBranchEditModal} handleSearch={handleSearch} DetailData={modifyData} modify={modify} setModify={setModify} />
        </>
    );
}

export default Contract001_02_01;