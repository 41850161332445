import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Modal} from 'react-bootstrap';
import { ReactTabulator } from 'react-tabulator';
import { jwtDecode } from 'jwt-decode';
import moment from 'moment';
import { API_BASE_URL } from '../../config/app-axios.jsx';
import { clearUserState } from '../../reducers/userReducers.jsx';
import { clearLangCodeState } from '../../reducers/langCodeReducers.jsx';

// Tabulator columns 목록
const initializeColumns = (t, type) => {
    let columns;

    // 공통 columns
    const commonColumns = [
        { title: 'NO', formatter: 'rownum', headerHozAlign: 'center', hozAlign:"center", headerSort:false, width:30 },
        { title: t('LOAN_APPLICATION.조회일자', { defaultValue: '조회일자' }), field: 'created_date', headerHozAlign: 'center', hozAlign: 'center', width:180, 
            formatter:function(cell, formatterParams, onRendered){
                var value = cell.getValue();
                    value = moment(value).format("DD/MM/YYYY");

                return value;
            }
        },
        { title: '지점코드', field: 'branch_code', headerHozAlign: 'center', hozAlign:"center", headerSort:false, width:120 },
        { title: '지점명', field: 'branch_name', headerHozAlign: 'center', hozAlign:"center", headerSort:false, width:150,
            formatter: function(cell, formatterParams, onRendered){
                cell.getElement().style.fontWeight = 'bold';
                return cell.getValue();
            },
        }
    ];

    // type이 2와 4인 경우에만 vertAlign: 'middle' 추가
    if (type === 2) {
        commonColumns.forEach(column => {
            column.vertAlign = 'middle';
        });
    } else if (type === 3) {
        // type이 3인 경우 created_date -> transaction_date로 변경
        commonColumns.find(column => column.field === 'created_date').field = 'transaction_date';
    } else if (type === 4) {
        commonColumns.forEach(column => {
            column.vertAlign = 'middle';
        });
        // type이 4인 경우 created_date -> transaction_date로 변경
        commonColumns.find(column => column.field === 'created_date').title = t('LOAN_APPLICATION.조회월', { defaultValue: '조회월' });
        commonColumns.find(column => column.field === 'created_date').field = 'transaction_date';

        commonColumns.find(column => column.field === 'transaction_date').formatter = function (cell, formatterParams, onRendered) {
            var value = cell.getValue();
            value = moment(value).format("YYYY-MM"); // 형식을 변경하려는 형식으로 수정
    
            return value;
        };
    }

    if (type === 1) {
        columns = [
            ...commonColumns,
            { title: t('CODE_INFORMATION.총건수', { defaultValue: '총건수' }), field: 'total_cnt', headerHozAlign: 'center', hozAlign: 'right', width:120, bottomCalc: "sum", bottomCalcFormatterParams: { decimal:",", thousand:".", negativeSign:true, precision:false } },
            { title: t(`LOAN_RECEIVABLE_INQUIRY.총금액`, { defaultValue: '총금액' }), field: 'total_amount', headerHozAlign: 'center', hozAlign: 'right', width:180, formatter:"money", formatterParams:{ decimal:",", thousand:".", negativeSign:true, precision:false }, bottomCalc: "sum", bottomCalcFormatter: "money", bottomCalcFormatterParams: { decimal:",", thousand:".", negativeSign:true, precision:false } },
            { title: t(`LOAN_RECEIVABLE_INQUIRY.총이자금액`, { defaultValue: '총이자금액' }), field: 'total_interest_amount', headerHozAlign: 'center', hozAlign: 'right', width:180, formatter:"money", formatterParams:{ decimal:",", thousand:".", negativeSign:true, precision:false }, bottomCalc: "sum", bottomCalcFormatter: "money", bottomCalcFormatterParams: { decimal:",", thousand:".", negativeSign:true, precision:false } },
            { title: t(`LOAN_RECEIVABLE_INQUIRY.총미수금액`, { defaultValue: '총미수금액' }), field: 'total_unpaid_amount', headerHozAlign: 'center', hozAlign: 'right', width:180, formatter:"money", formatterParams:{ decimal:",", thousand:".", negativeSign:true, precision:false }, bottomCalc: "sum", bottomCalcFormatter: "money", bottomCalcFormatterParams: { decimal:",", thousand:".", negativeSign:true, precision:false } },
        ];
    } else if (type === 2) {
        columns = [
            ...commonColumns,
            { title: t('CODE_INFORMATION.ZALO', { defaultValue: 'ZALO' }), headerHozAlign: 'center', hozAlign: 'center', width:120,
                formatter: function(cell, formatterParams, onRendered) {
                    const rowData     = cell.getData();
                    const otpCnt      = rowData.otp;
                    const otpOtherCnt = rowData.etc;
            
                    // CSS 스타일을 직접 설정하여 테두리로 구역 나누기
                    return `
                        <div class="d-flex border-bottom">
                            <div class="border-end w-50 text-start">
                                ${t('OTP')}
                            </div>
                            <div class="w-50 text-end">
                                ${otpCnt}
                            </div>
                        </div>

                        <div class="d-flex">
                            <div class="border-end w-50 text-start">
                                ${t('OTP 외')}
                            </div>
                            <div class="w-50 text-end">
                                ${otpOtherCnt}
                            </div>
                        </div>
                    `;
                },
            },
            { title: t(`LOAN_RECEIVABLE_INQUIRY.eMail`, { defaultValue: 'eMail' }), field: 'email', headerHozAlign: 'center', hozAlign: 'right', vertAlign: 'middle', width:150 },
        ];
    } else if (type === 3) {
        columns = [
            ...commonColumns,
            { title: t('CODE_INFORMATION.수납건수', { defaultValue: '수납건수' }), field: 'payment_cnt', headerHozAlign: 'center', hozAlign: 'right', width:120, bottomCalc: "sum", bottomCalcFormatterParams: { decimal:",", thousand:".", negativeSign:true, precision:false } },
			{ title: t(`LOAN_RECEIVABLE_INQUIRY.수납금액`, { defaultValue: '수납금액' }), field: 'payment_amount', headerHozAlign: 'center', hozAlign: 'right', width:120, formatter:"money", formatterParams:{ decimal:",", thousand:".", negativeSign:true, precision:false }, bottomCalc: "sum", bottomCalcFormatter: "money", bottomCalcFormatterParams: { decimal:",", thousand:".", negativeSign:true, precision:false } },
			{ title: t(`LOAN_RECEIVABLE_INQUIRY.이체건수`, { defaultValue: '이체건수' }), field: 'transfer_cnt', headerHozAlign: 'center', hozAlign: 'right', width:120, bottomCalc: "sum", bottomCalcFormatterParams: { decimal:",", thousand:".", negativeSign:true, precision:false } },
			{ title: t(`LOAN_RECEIVABLE_INQUIRY.이체금액`, { defaultValue: '이체금액' }), field: 'transfer_amount', headerHozAlign: 'center', hozAlign: 'right', width: 100, formatter:"money", formatterParams:{ decimal:",", thousand:".", negativeSign:true, precision:false }, bottomCalc: "sum", bottomCalcFormatter: "money", bottomCalcFormatterParams: { decimal:",", thousand:".", negativeSign:true, precision:false } },
        ];
    } else {
        columns = [
            ...commonColumns,
            { title: t('LOAN_APPLICATION.수수료구분', { defaultValue: '수수료구분' }), field: 'fee_type', headerHozAlign: 'center', hozAlign: 'center', vertAlign: 'middle', width:120 },
			{ title: t('LOAN_APPLICATION.지점별수수료', { defaultValue: '지점별수수료' }), field: 'branch_fee', headerHozAlign: 'center', hozAlign: 'right', vertAlign: 'middle', width:120, formatter:"money", formatterParams:{ decimal:",", thousand:".", negativeSign:true, precision:false }, bottomCalc: "sum", bottomCalcFormatter: "money", bottomCalcFormatterParams: { decimal:",", thousand:".", negativeSign:true, precision:false } },
			{ title: t('CODE_INFORMATION.ZALO', { defaultValue: 'ZALO' }), headerHozAlign: 'center', hozAlign: 'center', width:120,
                formatter: function(cell, formatterParams, onRendered) {
                    const rowData     = cell.getData();
                    const otpCnt      = rowData.otp;
                    const otpOtherCnt = rowData.etc;
            
                    // CSS 스타일을 직접 설정하여 테두리로 구역 나누기
                    return `
                        <div class="d-flex border-bottom">
                            <div class="border-end w-50 text-start">
                                ${t('OTP')}
                            </div>
                            <div class="w-50 text-end">
                                ${otpCnt}
                            </div>
                        </div>

                        <div class="d-flex">
                            <div class="border-end w-50 text-start">
                                ${t('OTP 외')}
                            </div>
                            <div class="w-50 text-end">
                                ${otpOtherCnt}
                            </div>
                        </div>
                    `;
                },
            },
			{ title: t(`LOAN_RECEIVABLE_INQUIRY.ZALO수수료`, { defaultValue: 'ZALO수수료' }), field: 'zalo_fee', headerHozAlign: 'center', hozAlign: 'right', vertAlign: 'middle', width: 100, formatter:"money", formatterParams:{ decimal:",", thousand:".", negativeSign:true, precision:false }, bottomCalc: "sum", bottomCalcFormatter: "money", bottomCalcFormatterParams: { decimal:",", thousand:".", negativeSign:true, precision:false } },
        ];
    }

    return columns;
};

// 지점통계 모달 (type 값에 따라 조회 내용 다름)
// type => 1 = 대출통계, 2 = SNS통계, 3 = 수납/이체통계, 4 = 수수료
const Statistics_detail = ({ showModal, handleClose, DetailData, type }) => {
    const { t, i18n }                     = useTranslation();
    const dispatch                        = useDispatch();
    const tableRef                        = useRef(null);

    // 지점통계 조회 관련
    const [rowsPerPage, setRowsPerPage]   = useState(20);
    const [totalCount, setTotalCount]     = useState(0);
    const [columns, setColumns]           = useState(initializeColumns(t, type));
    const [options, setOptions]           = useState([]);

    // remote 방식에 따른 페이징 처리
	const apiUrl   = API_BASE_URL;
	const userInfo = useSelector(state => state.login.userInfo);
	const token    = userInfo?.access_token;

	// ①. 강제로 쿠키 삭제를 통한 로그인 정보가 없는 경우
    const persistInfo = sessionStorage.getItem('persist:login');

    if (!persistInfo) {
        window.location.href = '/login';
    };

	// ②. 토큰 만료 체크 루틴
	if (token) {
		const decodedToken            = jwtDecode(token);
		const expirationTimeInSeconds = decodedToken.exp;
		const currentTimeInSeconds    = Math.floor(Date.now() / 1000);

		if (expirationTimeInSeconds < currentTimeInSeconds) {
			// 토큰이 만료되었을 경우 로그인 페이지로 리디렉션
			dispatch(clearUserState());
			dispatch(clearLangCodeState());
			localStorage.removeItem('langCodeInfo');
			window.location.href = '/login';
		};
	};

    // i18 선택된 언어에 따라 필요한 필드 값 번역
	const translateItem = useCallback((item, language) => ({
		...item,
		branch_type   : item.branch_type && t(`OFFICE_TYPE.${item.branch_type}`, { defaultValue : item.branch_type, lng: language }),
		branch_status : item.branch_status && t(`USER_STATUS.${item.branch_status}`, { defaultValue : item.branch_status, lng: language }),
	}), [t]);

    // 모달 오픈 시 조회
    useEffect(() => {
        // 테이블이 초기화될 때 호출되는 함수
        function handleTableBuilt() {
            // 여기에 테이블이 초기화된 후 실행해야 하는 코드를 추가
            tableRef.current?.getPage();
            tableRef.current?.getPageSize();
        }

        if (showModal && DetailData) {
            // 공통적인 Option 부분
            const commonOptions = {
                ajaxConfig  : {
                    method  : 'GET',
                    headers : {
                        'Content-Type'  : 'application/json',
                        'Authorization' : `Bearer ${token}`,
                    },
                },
                ajaxParams : {
                    search_date : (type === 3 || type === 4) ? DetailData.transaction_date : DetailData.created_date,
                    biz_code    : DetailData.biz_code,
                    page        : tableRef.current?.getPage(),
                    size        : tableRef.current?.getPageSize(),
                },
                layout                 : "fitColumns",
                pagination             : true,
                paginationMode         : "remote",
                paginationSize         : rowsPerPage,
                paginationSizeSelector : [20, 40, 60, 80, 100],
                selectable             : 1,
                ajaxResponse : function (url, params, response) {
                    const total_count    = response.total_count || 0;
                    const translatedData = response.data.map(item => translateItem(item, i18n.language));
                    const responseData = {
                        last_page : response.last_page,
                        data      : translatedData,
                    };
            
                    setTotalCount(total_count);
            
                    return responseData;
                }
            };

            // handleTableBuilt 함수 호출
            handleTableBuilt();

            if (type === 1) {
                setOptions({
                    ...commonOptions,
                    ajaxURL: `${apiUrl}/statistics/statistics_001_01`,
                });
            } else if (type === 2) {
                setOptions({
                    ...commonOptions,
                    ajaxURL: `${apiUrl}/statistics/statistics_002_01`,
                });
            } else if (type === 3) {
                setOptions({
                    ...commonOptions,
                    ajaxURL: `${apiUrl}/statistics/statistics_003_01`,
                });
            } else if (type === 4) {
                setOptions({
                    ...commonOptions,
                    ajaxURL: `${apiUrl}/statistics/statistics_004_01`,
                });
            }
        }
    }, [showModal, DetailData, type])

    return (
        <Modal show={showModal} onHide={handleClose} centered dialogClassName="modal-lg" backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{t('STAFF_MANAGEMENT.지점통계', { defaultValue: '지점통계' })}</Modal.Title>
            </Modal.Header>

            <Modal.Body className="text-start">
                <div className='row ms-0'>
                    <div className='col-md-2 bg-light d-flex align-items-center pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.사업자 번호`, { defaultValue: '사업자 번호' })}</div>
                    <div className='col-md-4 pt-2 pb-2'>
                        <span className='form-control border-0'>{DetailData.biz_no}</span>
                    </div>

                    <div className='col-md-2 bg-light d-flex align-items-center pt-2 pb-2'>{t(`LOAN_RECEIVABLE_INQUIRY.사업자명`, { defaultValue: '사업자명' })}</div>
                    <div className='col-md-4 pt-2 pb-2 d-flex align-items-center'>
                        <span className='form-control border-0'>{DetailData.biz_name}</span>
                    </div>
                </div>

                {/* Tabulator */}
                <div id="tabulator-total-info" className='d-flex justify-content-end fw-bold'>{t(`LOAN_APPLICATION.총 {{count}}건`, { count: totalCount, defaultValue: '총 {{count}}건' })}</div>
                <ReactTabulator
                    key={i18n.language + JSON.stringify(columns) + JSON.stringify(options)} // 언어 변경 및 옵션 변경에 따라 컴포넌트 리렌더링
                    onRef={(r) => (tableRef.current = r.current)}
                    columns={columns}
                    options={options}
                    placeholder="No Data Found"
                    className="responsive-tabulator"
                />
            </Modal.Body>

            <Modal.Footer className="d-block">
                <button className="btn btn-outline-gray me-1 mb-1 w-auto" onClick={handleClose}>
                    <i className="fa fa-backward"></i> {t('COMMON.취소', { defaultValue: '취소' })}
                </button>
            </Modal.Footer>
        </Modal>
    );
}

export default Statistics_detail;