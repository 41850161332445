import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { API_BASE_URL } from "../config/app-axios.jsx";
import { getUserInfo } from "../reducers/selectors/selectors.jsx";
import store, { persistor } from "../store.jsx";
import { clearUserState } from "../reducers/userReducers.jsx";
import { clearLangCodeState } from "../reducers/langCodeReducers.jsx";

const API = axios.create({
    baseURL : API_BASE_URL,
    headers : {
        'Content-Type' : 'application/json',
    },
    withCredentials : true,
});

// 헤더에 토큰을 담아서 처리하는 부분
API.interceptors.request.use(async (config) => {
    
    const userInfo = getUserInfo(store.getState());
    // ①. 강제로 쿠키 삭제를 통한 로그인 정보가 없는 경우
    const persistInfo = sessionStorage.getItem('persist:login');

    // 세션 저장소에 로그인 정보가 없는 경우
    if (!persistInfo) {
        window.location.href = '/login';
    };

    // 유저 정보가 존재하는 경우
    if (userInfo) {
        const token = userInfo.access_token;

        // 요청 헤더에 토큰을 담아서 전달
        config.headers.Authorization = `Bearer ${token}`;
    };

    return config;
});

// 401 발생시 토큰 만료 확인 후 로그인 페이지로 이동
API.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            // ②. 토큰 만료 체크 루틴
            const accessToken = getUserInfo(store.getState())?.access_token;
            
            if (accessToken) {
                const decodedToken            = jwtDecode(accessToken);
                const expirationTimeInSeconds = decodedToken.exp;
                const currentTimeInSeconds    = Math.floor(Date.now() / 1000);

                if (expirationTimeInSeconds < currentTimeInSeconds) {
                    // 토큰이 만료되었을 경우 로그인 페이지로 리디렉션
                    store.dispatch(clearUserState());
                    store.dispatch(clearLangCodeState());
                    localStorage.removeItem('langCodeInfo');
                    window.location.href = '/login';
                }
            }
        }

        // 401 이외의 다른 에러는 그대로 반환
        return Promise.reject(error);
    }
);

// 로그인
const fetchLogin = async ({ userId, password }) => {
    try {
        const response = await API.post('/login', {
            userId,
            password
        })

        return response.data.result.content;
    } catch (error) {
        console.error('Login Error:', error);
        throw error;
    }
};

// 비밀번호 변경
const editPassword = async ({ staffInfo }) => {
    try {
        const response = await API.post('/login/login_002', {
            staffInfo : staffInfo
        })

        return response.data.result;
    } catch (error) {
        console.error('Edit Password Error:', error);
        throw error;
    }
};

// ======================== 계약관리 화면 ======================== //
// ①. 계약관리 등록 및 수정
const saveContractInfo = async (data) => {
    const { formData } = data;

    try {
        const response = await API.post('/contract/contract_001', {
            formData
        });  

        return response.data;
    } catch (error) {
        console.error('Error Save Biz Info : ', error);
        throw error;
    }
};

// ②. 사업자번호 중복 체크
const checkBizNo = async (biz_no) => {
    try {
        const response = await API.post('/contract/biz_check', {
            biz_no
        });

        return response.data;
    } catch (error) {
        console.error('Error Check Biz No : ', error);
        throw error;
    }
};

// ③. 지점코드 중복 체크
const checkBranchCode = async (biz_code, branch_code) => {
    try {
        const response = await API.post('/contract/branch_check', {
            biz_code,
            branch_code
        });

        return response.data;
    } catch (error) {
        console.error('Error Check Branch Code : ', error);
        throw error;
    }
};

const apiFunctions = {
    fetchLogin,
    editPassword,
    saveContractInfo,
    checkBizNo,
    checkBranchCode,
};

export default apiFunctions;