import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearUserState } from '../../../reducers/userReducers';
import { clearLangCodeState } from '../../../reducers/langCodeReducers';
import { getUserInfo } from '../../../reducers/selectors/selectors';
import store, { persistor } from '../../../store';
import Profile_001 from './profile_001';
import userImg from '../../../assets/img/basic_user.jpg';

const DropdownProfile = () => {
	const dispatch = useDispatch();
	const userInfo = getUserInfo(store.getState());

	const handleLogout = () => {
		// 로그아웃 클릭 시 사용자 상태를 초기화하는 액션 디스패치
		dispatch(clearUserState());
		dispatch(clearLangCodeState());

		// localStorage에서 langCodeInfo 제거
		localStorage.removeItem('langCodeInfo');
	};

	const [showModal, setShowModal]   = useState(false);

	const handleOpenModal = () => {
		setShowModal(true);
	};

	return (
		<div className="navbar-item navbar-user dropdown">
			<a href="#/" className="navbar-link dropdown-toggle d-flex align-items-center me-3" data-bs-toggle="dropdown">
				{userInfo && <img src={userImg} alt={userInfo.name} />}
				<span>
					{userInfo && <span className="d-none d-md-inline">{userInfo.name}</span>}
					<b className="caret"></b>
				</span>
			</a>
			<div className="dropdown-menu dropdown-menu-end me-1">
				<a href="#/" className="dropdown-item" onClick={handleOpenModal}><i className="fa-solid fa-user"></i> 비밀번호 변경</a>

				{showModal && (
					<Profile_001 showModal={showModal} setShowModal={setShowModal} userInfo={userInfo} />
				)}

				<div className="dropdown-divider"></div>

				<Link to="/login" className='dropdown-item' style={{ color: 'red' }} onClick={handleLogout}><i className="fa-solid fa-power-off"></i> 로그아웃</Link>
			</div>
		</div>
	);
};

export default DropdownProfile;