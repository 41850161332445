import { createSelector } from "@reduxjs/toolkit";

// 유저 정보
const selectUserInfo = (state) => state.login.userInfo;

export const getUserInfo = createSelector(
    [selectUserInfo],
    (userInfo) => userInfo
);

// 언어 정보
const selectLangCodeInfo = (state) => state.langCode.langCodeInfo;

export const getLangCodeInfo = createSelector(
    [selectLangCodeInfo],
    (langCodeInfo) => langCodeInfo
);