const Menu = [
  { path: '/contract', icon: 'fa-solid fa-file-signature', title: '계약관리',
    children: [
      { path: '/contract/contract_001', title: '계약관리' },
    ]
  },
  { path: '/statistics', icon: 'fa-solid fa-chart-pie', title: '통계관리',
    children: [
      { path: '/statistics/statistics_001', title: '대출통계(일별)' },
      { path: '/statistics/statistics_002', title: 'SNS통계(일별)' },
      { path: '/statistics/statistics_003', title: '수납/이체통계(일별)' },
      { path: '/statistics/statistics_004', title: '수수료' },
    ]
  },
]

export default Menu;